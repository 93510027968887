import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { useContext, useEffect, useState } from 'react';
import { logEvent } from '../../../../../new-lib/analytics';
import { filterEventMap } from '../../../../../helpers/search-filters';
import { useRouter } from 'next/router';
import { updateQuery } from '../../../../../helpers/common';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import {
  SearchFiltersActionTypes,
  FilterMultiSelectTypes
} from '../../../../../reducers/search-filters/actions';
import DrawerFilterPresentation from './DrawerFilterPresentation';
interface ContainerProps {
  filterOptions: any;
  filterType: SearchFiltersTypes;
  filterName: any;
}

type FilterName = 'developers' | 'areas';

const DrawerFilterContainer = ({
  filterOptions,
  filterType,
  filterName
}: ContainerProps) => {
  const { searchFiltersDispatch, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const handleSelect = (value: any) => {
    let newValues = selectedValues;
    if (selectedValues.includes(value)) {
      newValues = selectedValues.filter((option: any) => option !== value);
    } else {
      newValues = [...selectedValues, value];
    }
    setSelectedValues(newValues);
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: filterType as FilterMultiSelectTypes,
        value: newValues
      }
    });
    logEvent.search(
      `filter_${filterEventMap(filterType)}`,
      'filtersMenu',
      'filter',
      searchFiltersState,
      { [filterType]: newValues }
    );
    if (!router.asPath.includes('/sahel-map')) {
      updateQuery(
        router,
        [
          { name: filterType, value: newValues.toString() },
          { name: 'page_number', value: 1 }
        ],
        undefined
      );
    }

    if (newValues.length == 0 && !router.asPath.includes('/sahel-map')) {
      updateQuery(
        router,
        [
          ...(!router.asPath.includes('/nawy-now')
            ? [{ name: 'page_number', value: 1 }]
            : [])
        ],
        [filterType]
      );
      updateQuery(router, [], [filterType]);
    }
  };
  useEffect(() => {
    setSelectedValues(searchFiltersState.searchFilters[filterType]);
  }, [searchFiltersState]);

  const [areasDevelopersMenu, setAreasDevelopersMenu] = useState(false);

  let selectedOptions = searchFiltersState.searchFilters?.[
    filterName as FilterName
  ]
    ?.map((optionId: number) =>
      filterOptions?.find(
        (option: { value: number }) => option.value === optionId
      )
    )
    .filter(Boolean);
  return (
    <DrawerFilterPresentation
      filterOptions={filterOptions}
      selectedOptions={selectedOptions}
      filterName={filterName}
      filterType={filterType}
      handleSelect={handleSelect}
      setAreasDevelopersMenu={setAreasDevelopersMenu}
      areasDevelopersMenu={areasDevelopersMenu}
    />
  );
};

export default DrawerFilterContainer;
