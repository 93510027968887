import styled from 'styled-components';
import FilterWrapper from './FilterWrapper';
import { FilterMenuProps } from './FiltersMenuContainer';
import SearchIcon from '../../../../new-components/svg-icons/SearchIcon';
import { useTranslation } from 'next-i18next';
import FilterIcon from '../../../../public/assets/icons/search/FilterIcon';
import { resetFilters } from '../../../../helpers/search-filters';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../context/SearchContext';
import { useSelector } from 'react-redux';

const MobileFiltersMenuPresentation = ({
  filters,
  btnText,
  searchResultsCount
}: FilterMenuProps) => {
  const { t } = useTranslation('search');
  const router = useRouter();
  const { searchFiltersDispatch, setShowFilters } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const isFetching = useSelector(
    (state: any) => state.searchResults.searchResults.isFetching
  );

  const handleButtonClick = () => {
    setShowFilters(false);
    if (router.pathname === '/') {
      router.push({ pathname: '/search', query: router.query });
    }
  };

  return (
    <Container>
      <div className="menu-header">
        <span className="header-title">
          <FilterIcon />
          {t('filters')}
        </span>
        <button
          className="reset-filters"
          onClick={() => resetFilters(router, searchFiltersDispatch)}
        >
          {t('reset_all')}
        </button>
      </div>
      <div className="filters-container">
        {filters.map((filter: any) => {
          return (
            <FilterWrapper
              filterName={filter.name}
              filterStyle={filter.filterStyle.mobile}
              filterOptions={filter.options}
              filterType={filter.type}
              withReset={filter.withReset}
              key={filter.name}
              prefix={filter.prefix}
              step={filter.step}
              singleSelect={filter.singleSelect}
            />
          );
        })}
      </div>
      <div className="apply-btn-container">
        {router.pathname === '/' ? (
          <button className={'apply-btn'} onClick={() => handleButtonClick()}>
            {t('search')}
          </button>
        ) : (
          <button
            className={
              searchResultsCount && searchResultsCount > 0
                ? 'apply-btn'
                : 'apply-btn disabled'
            }
            onClick={() => handleButtonClick()}
          >
            {isFetching ? (
              <span>...</span>
            ) : (
              <>
                {searchResultsCount && searchResultsCount > 0 ? (
                  <SearchIcon />
                ) : null}
                <span>{btnText}</span>
              </>
            )}
          </button>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fbfcfe;
  gap: 16px;

  .menu-header {
    display: flex;
    /* padding: 8px 16px; */
    padding: 0px 16px;

    justify-content: space-between;
    align-items: center;
    .header-title {
      font-family: materialBold;
      font-size: 20px;
      color: #1a2028;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .reset-filters {
      color: #1e6dac;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
      background-color: transparent;
      font-family: materialRegular;
      border: none;
      outline: none;
    }
  }
  .filters-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 16px 16px 16px;

    > div {
      padding: 24px 0;
      border-bottom: 1px solid #d3dbe6;
      &#bedrooms {
        border-bottom: none;
        padding-bottom: 0;
      }
      &:last-child {
        padding-bottom: 100px;
        border-bottom: none;
      }
    }
  }

  .apply-btn-container {
    min-width: 100%;
    padding: 8px 16px 24px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    outline: none;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      min-height: 55px;
      background-color: #015c9a;
      outline: none;
      border: none;
      border-radius: 10px;
      color: #fff;
      font-family: materialMedium;
      font-size: 14px;

      &.disabled {
        background-color: #7d7d7d;
        pointer-events: none;
      }
    }
  }
`;

export default MobileFiltersMenuPresentation;
