import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import appReducer from './reducers/appReducer';
import compareSlice from './slices/compareSlice';
import favoriteSlice from './slices/favoriteSlice';
import mapSlice from './slices/mapSlice';
import nawyNowSlice from './slices/nawyNowSlice';
import searchResultsSlice from './slices/searchResultsSlice';
import offersSlice from './slices/offersSlice';

const HOST = process.env.NEXT_PUBLIC_HOST;

const combinedReducer = combineReducers({
  app: appReducer,
  compare: compareSlice,
  favorites: favoriteSlice,
  map: mapSlice,
  nawyNow: nawyNowSlice,
  searchResults: searchResultsSlice,
  offers: offersSlice
});

const masterReducer = (state, action) => {
  // enabling ssr
  if (action.type === HYDRATE) {
    // use previous state

    const nextState = {
      ...state,
      ...action.payload
    };

    Object.keys(state).forEach(key => {
      if (key != 'app' && key !== 'map') {
        nextState[key] = state[key];
      }
    });

    return nextState;
  } else return combinedReducer(state, action);
};
export const makeStore = () => {
  return configureStore({
    reducer: masterReducer,
    devTools: !HOST.includes('nawy.com')
  });
};
export const wrapper = createWrapper(makeStore);
