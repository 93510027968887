import { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import useOnClickOutside from '../../hooks/ClickOutside';
import ButtonIcon from '../ui/ButtonIcon';
import CloseIcon from '../svg-icons/CloseIcon';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

interface MobileModalPropsType {
  children: React.ReactNode;
  isOpen: boolean;
  title?: string;
  withCloseBtn: boolean;
  isMap?: boolean;
  modalWidth?: string;
  modalHeight?: string;
  fullscreen?: boolean;
  closeFn: () => void;
}

const Modal = ({
  children,
  isOpen,
  title,
  withCloseBtn,
  closeFn,
  modalWidth,
  modalHeight,
  fullscreen,
  isMap
}: MobileModalPropsType) => {
  const showPopup = useSelector((state: any) => state.compare.show_popup);
  const { locale } = useRouter();

  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalRef, modalRef, () => {
    if (showPopup || withCloseBtn) {
      return;
    }
    closeFn();
    setTimeout(() => {
      document.body.style.pointerEvents = 'all';
    }, 500);
  });
  useEffect(() => {
    if (isOpen) {
      document.body.style.pointerEvents = 'none';
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.pointerEvents = 'all';
      document.body.style.overflow = '';
    };
  }, [isOpen, showPopup]);

  return (
    <CSSTransition in={isOpen} timeout={300} unmountOnExit classNames="modal">
      <Container
        isMap={isMap}
        modalWidth={modalWidth}
        modalHeight={modalHeight}
        fullscreen={fullscreen}
        dir={locale}
      >
        <div className="modal-content" ref={modalRef} data-test="modal">
          <div className="modal-header">
            {title && <span className="modal-title">{title}</span>}
            {withCloseBtn && (
              <ButtonIcon
                backgroundColor="#fbfbfb"
                backgroundHover="none"
                border="none"
                color="#646464"
                hoverColor="none"
                icon={<CloseIcon />}
                onClick={e => {
                  if (showPopup) {
                    return;
                  }
                  closeFn();
                }}
                containerClass="close-btn-wrapper"
                dataTest="close-modal"
              />
            )}
          </div>
          {children}
        </div>
      </Container>
    </CSSTransition>
  );
};

// Animation for LTR layout
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation for RTL layout
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
const Container = styled.div<{
  isMap?: boolean;
  modalWidth?: string;
  modalHeight?: string;
  fullscreen?: boolean;
  dir?: string;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999998;
  background-color: #000000b3;
  overflow-x: hidden;
  margin: 0 !important;

  ${({ dir }) =>
    dir === 'ar'
      ? css`
          right: 0;
          animation: ${slideInFromLeft} 0.2s ease forwards;
        `
      : css`
          left: 0;
          animation: ${slideInFromRight} 0.2s ease forwards;
        `}
  will-change: transform, opacity;

  .modal-content {
    position: relative;
    background-color: ${props =>
      props.isMap ? 'transparent' : `${props.theme.light.colors.white}`};
    margin: auto;
    width: ${props => (props.modalWidth ? props.modalWidth : '95%')};
    height: ${props => (props.modalHeight ? props.modalHeight : 'max-content')};
    max-height: ${props => (props.fullscreen ? '' : '90vh')};
    outline: none;
    border-radius: ${props => (props.fullscreen ? '0px' : '25px')};
    padding: ${props => (props.isMap ? '0px' : '25px 0px')};
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: all;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 25px;
      padding-left: 25px;

      .modal-title {
        font-size: ${props => props.theme.font.sizes.l};
        font-family: materialBold;
        color: ${props => props.theme.light.colors.black};
      }

      .close-btn-wrapper {
        position: absolute;
        inset-inline-end: 24px;
        top: 24px;
        z-index: 9999;
        button {
          border-radius: 50%;
          width: 35px;
          height: 35px;
          svg {
            width: 16px;
            height: 19px;
          }
        }
      }
    }
  }
`;
export default Modal;
