import { QUERY_PARAMS } from './queryParams';
import Cookies from 'universal-cookie';
import sbjs from 'sourcebuster';
import { getSearchResultsServerSide } from './api';

const BLOG_WHATSAPP_NUMBER = '01069275839';
const FACEBOOK_AB_TEST_WHATSAPP_NUMBER = '01021199808';
const GOOGLE_ORGANIC_WHATSAPP_NUMBER = '01029208551';
const GOOGLE_CPC_WHATSAPP_NUMBER = '01068888245';

export const responses = {
  no_error: 'Thank You for submitting your info. You will be contacted shortly',
  missing_phone: 'Please enter your phone to submit the form!',
  missing_name: 'Please enter your name to submit the form!',
  invalid_email: 'Please enter a valid email!'
};

export const map_response = (response, status = true) => {
  if (status) {
    return 'form_submitted_successfuly';
  } else if (response === responses.invalid_email) {
    return 'invalid_email';
  } else {
    return 'failed_to_submit';
  }
};

export const getImagePath = image_url => {
  if (!image_url) {
    return '/assets/imgs/broken.jpg';
  }

  if (image_url && image_url.includes('http')) {
    return image_url;
  }
  return process.env.NEXT_PUBLIC_IMAGE_DOMAIN + image_url;
};

export function ceilPrice(price) {
  if (price <= 1000_000) return Math.ceil(price / 100_000) * 100_000;
  if (price <= 10_000_000) return Math.ceil(price / 1000_000) * 1000_000;
  return Math.ceil(price / 10_000_000) * 10_000_000;
}

export function floorPrice(price) {
  if (price <= 1000_000) return Math.floor(price / 100_000) * 100_000;
  if (price <= 10_000_000) return Math.floor(price / 1000_000) * 1000_000;
  return Math.floor(price / 10_000_000) * 10_000_000;
}

export function ceilArea(area) {
  return Math.ceil(area / 50) * 50;
}

export function floorArea(area) {
  return Math.floor(area / 50) * 50;
}

//gets price options
export const fillPrice = (min_price, max_price) => {
  min_price = floorPrice(min_price);
  max_price = ceilPrice(max_price);
  const data = [];
  for (
    let i = Math.max(0, min_price);
    i <= Math.min(1000_000, max_price);
    i += 100_000
  )
    data.push(i);
  for (
    let i = Math.max(2000_000, min_price);
    i <= Math.min(10_000_000, max_price);
    i += 1000_000
  )
    data.push(i);
  for (let i = Math.max(20_000_000, min_price); i <= max_price; i += 10_000_000)
    data.push(i);
  return data;
};

//converts numbers to formats like 10k
export const compressNumber = num => {
  const thresholds = [
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ];
  for (const threshold of thresholds) {
    if (num >= threshold.value)
      return (num / threshold.value).toFixed(1) + threshold.symbol;
  }
  return num;
};

export function getSearchBody(query) {
  let body = {};
  for (const [key, val] of Object.entries(query)) {
    if (QUERY_PARAMS[key]) {
      body = { ...body, ...QUERY_PARAMS[key].toApiQuery(val) };
    } else {
      body = { ...body, ...{ [key]: val } };
    }
  }

  return body;
}

export const getFilterObj = filters => {
  const query = {};
  const filterToStr = {
    developer: 'developer_id',
    area: 'area_id',
    compound: 'compound_id',
    property_type: 'property-type_id'
  };
  for (const [key, val] of Object.entries(filters)) {
    if (!val) continue;
    if (filterToStr[key]) query[filterToStr[key]] = val.value;
  }
  return query;
};

//converts object to query string format
export const objToQuery = obj => {
  const cookies = new Cookies();
  const arr = [];
  arr.push(`token=${cookies.get('token')}`);
  for (const [key, value] of Object.entries(obj)) {
    if (!value) continue;
    arr.push(`${key}=${value}`);
  }
  return arr.join('&');
};

export const commifyNumber = x => {
  if (!x) {
    return '';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const unCommifyNumber = x => {
  return x.replace(/\,/g, '');
};

export const getReadyBy = (minReadyBy, maxReadyBy) => {
  let thisYear = new Date().getFullYear();
  minReadyBy = minReadyBy && minReadyBy.split('-')[0];
  maxReadyBy = maxReadyBy && maxReadyBy.split('-')[0];
  if (
    (minReadyBy && minReadyBy < thisYear) ||
    (maxReadyBy && maxReadyBy < thisYear)
  ) {
    return thisYear;
  }
  if (minReadyBy) {
    return minReadyBy;
  }
  if (maxReadyBy) {
    return maxReadyBy;
  }
  return null;
};

export function showIntercom(message, link) {
  window.Intercom('showNewMessage', `${message} \n ${link}`);
}

export function stripHtmlTags(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, ' ');
}

export function replaceHtmlEntities(str) {
  const newStr = str.replace(/&nbsp;/g, ' ');
  return newStr.replace(/&amp;/g, '&');
}

export const getPageLocation = currentUrl => {
  let ans;
  const pages = [
    'property',
    'compound',
    'search',
    'how-it-works',
    'favorites',
    'move-now',
    'top-compounds',
    'prime-compounds',
    'real-estate-360',
    '/',
    ''
  ];
  const res = currentUrl.split('/');
  for (let i = 0; i < pages.length; ++i) {
    if (res.includes(pages[i])) {
      if (pages[i] === '' || pages[i] === '/') ans = 'home';
      else ans = pages[i];
      break;
    }
  }
  return ans;
};
export function prepareItemsGoogleAds(items) {
  const resItems =
    items &&
    items.map(item => {
      let des = '';
      if (item.number_of_bedrooms && items.number_of_bedrooms != 0) {
        des += `${item.number_of_bedrooms} beds,`;
      }
      if (item.number_of_bathrooms && items.number_of_bathrooms != 0) {
        des += `${item.number_of_bathrooms} baths,`;
      }
      if (
        (item.min_unit_area && item.min_unit_area != 0) ||
        (item.max_unit_area && item.max_unit_area != 0)
      ) {
        if (item.min_unit_area && item.min_unit_area != 0) {
          des += `${item.min_unit_area}`;
        }
        if (item.max_unit_area && item.max_unit_area != 0) {
          des += `-${item.max_unit_area} m2`;
        } else {
          des += `m2`;
        }
      }
      const obj = {
        id: item.id,
        listing_name: item.name,
        image_url: item.images[0].image_path,
        city_name: `${item.compound.area.name},Egypt`,
        property_type: item.property_type.name,
        listing_type: item.resale ? 'Resale' : 'Primary',
        Address: `${item.lat},${item.long}`,
        google_business_vertical: 'real_estate'
      };
      if (des != '') {
        return { ...obj, des };
      } else {
        return obj;
      }
    });
  return resItems;
}

export const numFormatter = num => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1).replace(/(\.0+|0+)$/, '') + 'K';
  } else if (num >= 1000000) {
    if (num === 20000000) return '20M+';
    return (num / 1000000).toFixed(1).replace(/(\.0+|0+)$/, '') + 'M';
  } else if (num < 1000) {
    return num.toFixed(1).replace(/(\.0+|0+)$/, '');
  }
};

export const checkLanguage = locale => {
  return locale == 'en' ? '' : '/ar';
};

export const getPhoneNumber = referer => {
  if (referer && referer.includes('blog')) return '01069275839';
  if (
    sbjs.get &&
    sbjs.get.current.src === 'Facebook' &&
    sbjs.get.current.mdm === 'AB_test'
  )
    return '01021199808';
  else if (
    sbjs.get &&
    sbjs.get.current.src === 'google' &&
    sbjs.get.current.mdm === 'organic'
  )
    return '01029208551';
  else if (
    sbjs.get &&
    sbjs.get.current.src === 'google' &&
    sbjs.get.current.mdm === 'cpc'
  )
    return '01068888245';
  return process.env.NEXT_PUBLIC_PHONE_NUMBER || '01065888849';
};

export const getWhatsappNumber = referer => {
  if (referer && referer.includes('blog')) return BLOG_WHATSAPP_NUMBER;
  if (
    sbjs.get &&
    sbjs.get.current.src === 'Facebook' &&
    sbjs.get.current.mdm === 'AB_test'
  )
    return FACEBOOK_AB_TEST_WHATSAPP_NUMBER;
  else if (
    sbjs.get &&
    sbjs.get.current.src === 'google' &&
    sbjs.get.current.mdm === 'organic'
  )
    return GOOGLE_ORGANIC_WHATSAPP_NUMBER;
  else if (
    sbjs.get &&
    sbjs.get.current.src === 'google' &&
    sbjs.get.current.mdm === 'cpc'
  )
    return GOOGLE_CPC_WHATSAPP_NUMBER;

  return process.env.NEXT_PUBLIC_WHATSAPP_NUMBER || '01065888849';
};

export const cutStringWithHtml = (s, limit) => {
  if (s.Length < limit) return s;

  let okIndex = 0;
  let inClosingTag = false;
  let numOpenTags = 0;

  for (let i = 0; i < limit; i++) {
    if (s[i] == '<') {
      if (s[i + 1] == '/') {
        inClosingTag = true;
      } else {
        numOpenTags++;
      }
    }
    if (s[i] == '>') {
      if (s[i - 1] == '/') {
        numOpenTags--;
      }
      if (inClosingTag) {
        numOpenTags--;
      }
    }

    if (numOpenTags == 0) okIndex = i;
  }
  return s.slice(0, okIndex + 1);
};

export const isWebView = () => {
  if (typeof window === undefined) {
    return false;
  }

  let navigator = window.navigator;

  const standalone = navigator.standalone;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  let is_webView = ios ? !standalone && !safari : userAgent.includes('wv');
  return is_webView;
};
export const loadSearchResults = async (type, query, lang, clientId) => {
  return getSearchResultsServerSide(
    { ...query, show: type },
    lang,
    clientId
  ).then(res => {
    return {
      values: res.values,
      totalproperties: res.total_properties
    };
  });
};

export const handlePrepareFiltersOptions = filters => {
  //{label:"",value:""}

  let handledOptions = {};
  for (let key in filters) {
    switch (key) {
      case 'property_type':
      case 'area':
        handledOptions[key] = filters[key].map(curr => ({
          label: curr.name.toLowerCase(),
          value: curr.id
        }));
        break;
      case 'compounds':
        handledOptions[key] = filters[key].map(curr => {
          let newslug = curr.slug.split('-');
          newslug.shift();
          let label = newslug.join(' ');
          return {
            label: label,
            value: curr.id
          };
        });
        break;
      default:
        handledOptions[key] = filters[key];
    }
  }
  return handledOptions;
};

export const convertSlugIntoFilters = (slug, filters) => {
  let query = {};
  let Url = slug.split('-');
  for (let key in filters) {
    switch (key) {
      case 'property_type':
        print('aloo', filters[key]);
        var val = searchForLabelFilter(slug, filters[key]);
        if (val != '') {
          query[key] = String(val);
        }
        break;

      case 'bedrooms':
        var val = searchForWordGrabBefore(Url, [
          'bedrooms',
          'bedroom',
          'غرف',
          'غرفة'
        ]);
        if (val != '') {
          query[key] = `${val},${val}`;
        }
        break;
      case 'area':
        var val = searchForWordandCheck(Url, ['in', 'في'], filters[key]);
        if (val.thevalue != '') {
          query[key] = val.thevalue;
        } else {
          const comp = filters['compounds'].filter(
            curr => val.label == curr.label
          );
          if (comp.length > 0) {
            query['compound'] = comp[0].value;
          }
        }
        break;
      case 'finishing':
        const label = searchForWord(slug, filters[key]);
        if (label.length > 0) {
          query[key] = label;
        }

        break;
    }
  }
  return query;
};

const searchForLabelFilter = (url, filterOption) => {
  let filterValue = '';
  filterOption.map(curr => {
    if (url.includes(curr.label)) {
      filterValue = curr.value;
    }
  });
  return filterValue;
};

const searchForWordGrabBefore = (url, word) => {
  let indx = -1;
  word.map(curr => {
    if (url.indexOf(curr) != -1) {
      indx = url.indexOf(curr);
    }
  });

  if (indx == -1) return '';
  if (indx == 0) return url[indx];
  return url[indx - 1];
};

const searchForWordandCheck = (url, word, filterOption) => {
  let indx;
  word.map(curr => {
    if (url.indexOf(curr) != -1) {
      indx = url.indexOf(curr);
    }
  });

  const newURl = url.slice(indx + 1, url.length);

  let label = '';
  newURl.map((curr, ind) => {
    if (ind == newURl.length - 1) {
      label += curr;
      indx;
    } else {
      label += curr + ' ';
    }
  });
  let valuee = '';
  valuee = filterOption.filter(curr => label == curr.label);

  if (valuee.length > 0) {
    return { thevalue: valuee[0].value, label: label };
  } else {
    return { thevalue: '', label: label };
  }
};

const searchForWord = (url, filtersOption) => {
  let found = '';

  for (var i = filtersOption.length - 1; i >= 0; i--) {
    let firstvalue = filtersOption[i][0];
    let secondvalue = filtersOption[i][1];
    if (url.includes(firstvalue)) {
      found = filtersOption[i][2];
      break;
    }

    if (url.includes(secondvalue)) {
      found = filtersOption[i][2];
      break;
    }
  }
  return found;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const handleSeoHeader = header => {};

export const getRequest = (query, asPath) => {
  if (query.property_slug)
    return { property_id: query.property_slug.split('-')[0] };
  else if (query.compound_slug)
    return { compound_id: query.compound_slug.split('-')[0] };
  return { url: asPath };
};

export const mergeOptions = options => {
  let allOptions = [];
  for (let type in options) {
    let typeLabel = type.slice(0, -1); // remove the 's' at the end
    allOptions.push(
      ...options[type].map(option => ({
        id: `${typeLabel}-${option.id}`,
        name: option.name
      }))
    );
  }
  return allOptions;
};

export const getImages = images => {
  return images.map(image => {
    return getImagePath(image.image_path);
  });
};

export const isPrime = path => {
  return (
    path.includes('top-compounds') ||
    path.includes('prime-compounds') ||
    path.includes('sell-my-property') ||
    path.includes('real-estate-360')
  );
};
