import { useReducer } from 'react';
import {
  searchFiltersInitialState,
  searchFiltersReducer
} from '../reducers/search-filters/reducer';

const useSearchFilters = () => {
  const [searchFiltersState, dispatch] = useReducer(
    searchFiltersReducer,
    searchFiltersInitialState
  );

  return { searchFiltersState, dispatch };
};

export default useSearchFilters;
