import { useTranslation } from 'next-i18next';
import ContactIcon from '../../../../new-components/svg-icons/ContactIcon';

interface FormHeaderProps {
  hasIcon: boolean;
}

const FormHeader = ({ hasIcon }: FormHeaderProps) => {
  const { t } = useTranslation('contact');
  return (
    <div className="form-header">
      {hasIcon && <ContactIcon />}
      <div className="headline-3 headline-title">{t('need_expert_advice')}</div>
      <p className="text-3">{t('fill_out_the_form')}</p>
    </div>
  );
};

export default FormHeader;
