import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { commifyNumber } from '../../../../../lib/helpers';

interface ContainerProps {
  prefix?: string;
  value: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  applyFilter: () => void;
  placeholder?: string;
}

const InputValue = ({
  prefix,
  value,
  onChange,
  applyFilter,
  placeholder
}: ContainerProps) => {
  const { t } = useTranslation('common');
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(commifyNumber(value));
  }, [value]);

  return (
    <Container>
      <input
        type="text"
        value={inputValue}
        onChange={onChange}
        onBlur={applyFilter}
        placeholder={placeholder}
      />
      {prefix && value > 0 && <span>{t(prefix)}</span>}
    </Container>
  );
};

const Container = styled.div`
  width: 50%;
  padding: 8px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #c6d0de;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  input {
    color: #1a2028;
    font-size: 14px;
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;

    ::placeholder {
      color: #757575 !important;
      font-size: 13px;
      line-height: 150%;
      font-family: materialLight;
    }
  }
  span {
    color: #5b6573;
    font-size: 11px;
  }
`;

export default InputValue;
