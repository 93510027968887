import { createSlice } from '@reduxjs/toolkit';
import { logEvent } from '../../new-lib/analytics';

interface InitialState {
  isNawyExclusive: boolean;
  areas_ids: number[];
  developers_ids: number[];
  offers_filters: string[];
}
const initialState: InitialState = {
  isNawyExclusive: false,
  areas_ids: [],
  developers_ids: [],
  offers_filters: []
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setNawyExclusive: state => {
      logEvent.click('filter', 'offer_filters', 'offers', {
        offers_filters: {
          type: 'nawy_exclusive',
          action: state.isNawyExclusive ? 'remove' : 'add',
          nawy_exclusive: state.isNawyExclusive
        }
      });

      state.isNawyExclusive = !state.isNawyExclusive;
    },
    setAreasIds: (state, action) => {
      let filterAction: 'add' | 'remove' = 'add';

      if (action.payload.length === 0) {
        state.areas_ids = [];
        return;
      }

      if (state.areas_ids.includes(action.payload)) {
        state.areas_ids = state.areas_ids.filter(id => id !== action.payload);
        filterAction = 'remove';
      } else {
        state.areas_ids = [...state.areas_ids, action.payload];
      }

      logEvent.click('filter', 'offer_filters', 'offers', {
        offers_filters: {
          type: 'areas_search',
          action: filterAction,
          area_id: action.payload
        }
      });
    },
    setDevelopersIds: (state, action) => {
      let filterAction: 'add' | 'remove' = 'add';

      if (action.payload.length === 0) {
        state.developers_ids = [];
        return;
      }
      if (state.developers_ids.includes(action.payload)) {
        state.developers_ids = state.developers_ids.filter(
          id => id !== action.payload
        );
      } else {
        state.developers_ids = [...state.developers_ids, action.payload];
      }

      logEvent.click('filter', 'offer_filters', 'offers', {
        offers_filters: {
          type: 'developers_search',
          action: filterAction,
          developer_id: action.payload
        }
      });
    },
    setOfferFilters: (state, action) => {
      let filterAction: 'add' | 'remove' = 'add';

      if (action.payload === '') {
        state.offers_filters = [];
        return;
      }

      if (state.offers_filters.includes(action.payload)) {
        state.offers_filters = state.offers_filters.filter(
          filter => filter !== action.payload
        );
        filterAction = 'remove';
      } else {
        state.offers_filters = [...state.offers_filters, action.payload];
      }

      logEvent.click('filter', 'offer_filters', 'offers', {
        offers_filters: {
          type: 'offer_types',
          action: filterAction,
          offer_type: action.payload
        }
      });
    },
    resetFilters: state => {
      state.isNawyExclusive = false;
      state.areas_ids = [];
      state.developers_ids = [];
      state.offers_filters = [];
    }
  }
});

export const {
  setNawyExclusive,
  setAreasIds,
  setDevelopersIds,
  setOfferFilters,
  resetFilters
} = offersSlice.actions;

export default offersSlice.reducer;
