import { createSlice } from '@reduxjs/toolkit';
import { SearchSelection } from '../../features/search/hooks/LocationFilter';

interface InitialState {
  selectedType: 'areas' | 'compounds';
  areas: SearchSelection[];
  compounds: SearchSelection[];
}
const initialState: InitialState = {
  selectedType: 'areas',
  areas: [],
  compounds: []
};

export const nawyNowSlice = createSlice({
  name: 'nawyNow',
  initialState,
  reducers: {
    setAreas(state, action) {
      state.areas = action.payload;
    },
    setCompounds(state, action) {
      state.compounds = action.payload;
    },
    setSelectedType(state, action) {
      state.selectedType = action.payload;
    },
    resetFilters(state) {
      return (state = { ...initialState, selectedType: state.selectedType });
    }
  }
});
export const { setAreas, setCompounds, setSelectedType, resetFilters } =
  nawyNowSlice.actions;

export default nawyNowSlice.reducer;
