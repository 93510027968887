import { SahelInfoResponse } from '../dtos';

export const enum MapMode {
  CalculateDistance = 'calculate-distance',
  Search = 'search',
  Compound = 'compound'
}

export const enum ZoomType {
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out'
}

export type MapCoords = [number, number];

export interface MarkerLocation {
  id: number;
  name: string;
  longitude: number;
  latitude: number;
  type: MapMarkerType;
  category: 'compound' | 'area';
  coordinates: [];
}

export interface MapMarkersProps {
  locations: MarkerLocation[];
}

export interface DefaultSahelEntity {
  id: number;
  name: string;
  northcoast_km: number;
  location_point: MapCoords;
  polygon_points: number[][][];
}
export type Compound = DefaultSahelEntity & {
  developer: {
    logo_path: string;
    id: number;
    name: string;
  };
  image: string;
  min_price: number;
};

export interface MapProps {
  info: SahelInfoResponse;
}

export const enum PropertySaleType {
  All = 'all',
  Developer_Sale = 'developer_sale',
  Resale = 'resale'
}

export type Destination = 'a' | 'b';

export const enum QuickFilter {
  PRICE = 'price',
  DELIVERY = 'delivery',
  PROPERTY_TYPE = 'property_types',
  NAWY_NOW = 'nawy_now'
}

export interface FilterChipProps {
  type?: QuickFilter;
  icon: JSX.Element;
  label: string;
}

export enum MapMarkerType {
  DEFAULT = 'default',
  SELECTED = 'selected',
  FIRST_DESTINATION = 'first-destination',
  SECOND_DESTINATION = 'second-destination',
  SEARCH_RESULT = 'search-result'
}
