import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { updateQuery } from '../../../../../helpers/common';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import {
  SearchFiltersActionTypes,
  FilterMultiSelectTypes
} from '../../../../../reducers/search-filters/actions';
import { FilterStyles } from '../FilterWrapper';
import ChipFilterPresentation from './ChipFilterPresentation';
import CheckboxFilterPresentation from './CheckboxFilterPresentation';
import NumberSelectFilterPresentation from './NumberSelectFilterPresentation';
import { isEqual } from '../../../../../helpers/isEqual';
import { filterSingleSelectInputsTypes } from '../../../../../new-lib/types/search-filters';

interface ContainerProps {
  filterOptions: any;
  filterType: SearchFiltersTypes;
  filterName: string;
  filterStyle: FilterStyles;
  withSeeMore?: boolean;
  singleSelect?: boolean;
  maxSelectNumber?: number;
}

const SelectFilterContainer = ({
  filterOptions,
  filterType,
  filterName,
  filterStyle,
  withSeeMore,
  singleSelect,
  maxSelectNumber
}: ContainerProps) => {
  const { searchFiltersDispatch, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();
  const [selectedValues, setSelectedValues] = useState<any>([]);

  const getDeliveryDateOptions = () => {
    return filterOptions.map((option: any, index: number) => {
      if (index === filterOptions.length - 1) {
        return {
          ...option,
          value: option.value,
          options: { displayedValue: `${option.options.displayedValue}+` }
        };
      } else if (index === 0) {
        return {
          ...option,
          value: option.value,
          options: { displayedValue: 'delivered' }
        };
      } else {
        return {
          ...option,
          value: option.value,
          options: { displayedValue: option.options.displayedValue }
        };
      }
    });
  };

  const reorderOptions = (options: any[], selected: any[]) => {
    const selectedOptions = options.filter(option =>
      selected?.includes(option.value)
    );
    const unselectedOptions = options.filter(
      option => !selected?.includes(option.value)
    );
    return [...selectedOptions, ...unselectedOptions];
  };

  const handleSelect = (option: any) => {
    let newValues = selectedValues;
    if (!singleSelect) {
      if (selectedValues.includes(option.value)) {
        newValues = selectedValues.filter(
          (value: any) => value !== option.value
        );
      } else {
        newValues = [...selectedValues, option.value];
      }
      setSelectedValues(newValues);
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: filterType as FilterMultiSelectTypes,
          value: newValues
        }
      });
      if (filterType === SearchFiltersTypes.AMENITIES) {
        if (option.value == 1) {
          searchFiltersDispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: SearchFiltersTypes.HAS_GARDEN,
              value: true
            }
          });
        }else if (option.value == 2) {
          searchFiltersDispatch({
            type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
            payload: {
              name: SearchFiltersTypes.HAS_ROOF,
              value: true
            }
          });
      }}
      if (!router.asPath.includes('/sahel-map')) {
        updateQuery(
          router,
          [{ name: filterType, value: newValues.toString() }],
          undefined
        );
      }

      if (newValues.length == 0 && !router.asPath.includes('/sahel-map')) {
        updateQuery(
          router,
          [
            ...(!router.asPath.includes('/nawy-now')
              ? [{ name: 'page_number', value: 1 }]
              : [])
          ],
          [filterType]
        );
        updateQuery(router, [], [filterType]);
      }
    } else {
      if (filterType === SearchFiltersTypes.SORT_BY) {
        if (
          isEqual(selectedValues, {
            order_by: option.value,
            order_direction: option.options.direction
          })
        ) {
          searchFiltersDispatch({
            type: SearchFiltersActionTypes.UPDATE_SORTING_ORDER,
            payload: { order_by: '', order_direction: '' }
          });
          updateQuery(router, undefined, ['order_by', 'order_direction']);
          return;
        }

        const payload = {
          order_by: option.value,
          order_direction: option.options.direction
        };
        setSelectedValues(payload);
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_SORTING_ORDER,
          payload: payload
        });

        if (option.value === '') {
          updateQuery(router, undefined, ['order_by', 'order_direction']);
        } else {
          updateQuery(
            router,
            [
              { name: 'order_by', value: payload.order_by },
              { name: 'order_direction', value: payload.order_direction }
            ],
            [filterType]
          );
        }
      } else {
        if (isEqual(selectedValues, option.value)) {
          newValues = null;
        } else {
          newValues = option.value;
        }
        setSelectedValues(newValues);
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: filterType as filterSingleSelectInputsTypes,
            value: newValues
          }
        });
        if (!router.asPath.includes('/sahel-map')) {
          if (newValues !== null) {
            updateQuery(
              router,
              [{ name: filterType, value: newValues }],
              undefined
            );
          } else {
            updateQuery(router, [], [filterType]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (searchFiltersState.searchFilters[filterType]) {
      setSelectedValues(searchFiltersState.searchFilters[filterType]);
    } else if (searchFiltersState.searchFilters[filterType] === undefined) {
      setSelectedValues(null);
    }
  }, [searchFiltersState]);

  switch (filterStyle) {
    case FilterStyles.CHIP_SELECT:
      return (
        <ChipFilterPresentation
          filterOptions={
            filterType == SearchFiltersTypes.DELIVERY_DATE
              ? getDeliveryDateOptions()
              : filterOptions
          }
          selectedValues={selectedValues}
          handleSelect={handleSelect}
          singleSelect={singleSelect}
          isNawyNowSelected={
            filterType == SearchFiltersTypes.DELIVERY_DATE &&
            searchFiltersState.searchFilters.nawy_now
          }
        />
      );
    case FilterStyles.CHECKBOX:
      return (
        <CheckboxFilterPresentation
          filterOptions={
            filterType == SearchFiltersTypes.AREAS ||
            filterType == SearchFiltersTypes.DEVELOPERS
              ? reorderOptions(filterOptions, selectedValues)
              : filterOptions
          }
          filterName={filterName}
          handleSelect={handleSelect}
          selectedValues={selectedValues}
          withSeeMore={withSeeMore}
          isNawyNowSelected={searchFiltersState.searchFilters.nawy_now}
          singleSelect={singleSelect}
        />
      );
    case FilterStyles.NUMBER_SELECT:
      return (
        <NumberSelectFilterPresentation
          filterOptions={filterOptions}
          selectedValues={selectedValues}
          handleSelect={handleSelect}
          maxSelectNumber={maxSelectNumber}
        />
      );
    case FilterStyles.RADIO_BUTTON:
      return (
        <CheckboxFilterPresentation
          filterOptions={
            filterType == SearchFiltersTypes.DELIVERY_DATE
              ? getDeliveryDateOptions()
              : filterOptions
          }
          filterName={filterName}
          handleSelect={handleSelect}
          selectedValues={selectedValues}
          singleSelect={true}
          isNawyNowSelected={searchFiltersState.searchFilters.nawy_now}
        />
      );

    default:
      return null;
  }
};

export default SelectFilterContainer;
