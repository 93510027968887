import { Map } from '../../../new-lib/types/common';
import { OptionListValues } from './OptionListValues';
import { SingleValue } from './SingleValue';

type InputValues = string[] | number[] | object[];

export class ObjectOptionListValues extends OptionListValues {
  constructor(public inputValues: InputValues, private objectRule: Map) {
    super(inputValues);
    this.objectRule = objectRule;
    this.createOptions();
  }

  createOptions = (): void => {
    const optionResults = this.inputValues.map((curr: any) => {
      const mapedValueKey = this.objectRule['value'];
      const value = curr[mapedValueKey];
      const options: Map = {};

      for (const key in this.objectRule) {
        if (key != 'value') {
          let access = this.objectRule[key].split('.');
          let val = curr;
          access.map((key: string) => {
            val = val[key];
          });
          options[key] = val;
        }
      }
      const inputValue = Object.create(SingleValue);

      inputValue.constructor(value, options);
      return inputValue;
    });

    this.outputOptions = optionResults;
  };
}
