type AnyObject = { [key: string]: any };

export const isEqual = (value1: any, value2: any): boolean => {
  if (value1 === value2) {
    return true; // handles cases where both are null, undefined, or the same primitive value
  }

  if (typeof value1 !== typeof value2 || value1 == null || value2 == null) {
    return false; // different types or one of them is null or undefined
  }

  if (typeof value1 === 'object') {
    if (Array.isArray(value1) !== Array.isArray(value2)) {
      return false; // one is an array, the other is not
    }

    if (Array.isArray(value1)) {
      return arraysEqual(value1, value2); // compare arrays
    } else {
      return objectsEqual(value1 as AnyObject, value2 as AnyObject); // compare objects
    }
  }

  return false; // fallback, should not reach here
};

function arraysEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false; // arrays of different lengths are not equal
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!isEqual(arr1[i], arr2[i])) {
      return false; // found a mismatch
    }
  }

  return true;
}

function objectsEqual(obj1: AnyObject, obj2: AnyObject): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // objects with different numbers of keys are not equal
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
      return false; // key missing in obj2 or values for key are not equal
    }
  }

  return true;
}
