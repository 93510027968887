import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import ButtonIcon from '../../../../new-components/ui/ButtonIcon';
import CloseIcon from '../../../../new-components/svg-icons/CloseIcon';
import { theme } from '../../../../styles/theme';
import { useRouter } from 'next/router';

interface ChildProps {
  selection: SearchSelection;
  handleRemove: (selection: SearchSelection) => void;
}

export interface SearchSelection {
  name: string;
  id: number;
  category: string;
}

export const SelectedItem = ({ selection, handleRemove }: ChildProps) => {
  const { pathname } = useRouter();
  return (
    <SelectedItemContainer
      className={
        selection.category == 'compound'
          ? 'selected-search compound-search'
          : 'selected-search'
      }
    >
      <span className="text-3">{selection.name}</span>
      {pathname.includes('developer') ||
      pathname.includes('area') ||
      selection.category == 'compound' ? (
        <></>
      ) : (
        <div className="delete-icon" data-cy="remove-input-selection">
          <ButtonIcon
            icon={<CloseIcon fill={theme.light.colors.grey} />}
            backgroundColor="transparent"
            btnClass="close-btn"
            onClick={() => handleRemove(selection)}
            color="none"
            border="none"
          />
        </div>
      )}
    </SelectedItemContainer>
  );
};

interface SearchSelectionsProps {
  clearClick: () => void;
  selectedSearches: SearchSelection[];
  handleRemove: (selection: SearchSelection) => void;
}

const SearchSelections = ({
  clearClick,
  selectedSearches,
  handleRemove
}: SearchSelectionsProps) => {
  const { t } = useTranslation('common');
  return (
    <Container>
      {selectedSearches.map(item => {
        return (
          <SelectedItem
            key={item.id}
            selection={item}
            handleRemove={handleRemove}
          />
        );
      })}
      {selectedSearches.length > 1 && (
        <span className="clear-all" onClick={clearClick}>
          {t('clear_all')}
        </span>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
  margin-top: 8px;

  .clear-all {
    color: #015c9a;
    text-decoration: underline;
    font-size: ${props => props.theme.font.sizes.s};
    height: fit-content;
    align-self: center;
    font-family: materialMedium;
    cursor: pointer;
  }
`;

const SelectedItemContainer = styled.div`
  background-color: #e4ebf2;
  border-radius: 8px;
  padding: 4px;
  padding-inline-start: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: fit-content;

  &.compound-search {
    max-width: unset;
    border: none;
    span {
      color: ${props => props.theme.light.colors.black};
    }
  }

  span {
    color: ${props => props.theme.light.colors.black};
    font-size: 14px;
    font-family: materialMedium;
  }
  .delete-icon button {
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 50%;
      height: auto;
    }
  }
`;

export default SearchSelections;
