import { useRouter } from 'next/router';
import Dropdown from './Dropdown';
import { getCountries } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import ar from 'react-phone-number-input/locale/ar.json';
import Select from 'react-select';
import { components } from 'react-select';
import ImageWrapper from '../../../../../new-components/ImageWrapper';

const CountrySelect = ({
  touched,
  error,
  country,
  setCountry,
  resetInput,
  isClosed
}: any) => {
  const { locale } = useRouter();

  const countryOptions = getCountries()
    .filter(countryCode => countryCode !== 'IL')
    .map(countryCode => ({
      value: countryCode,
      label: `${locale === 'en' ? en[countryCode] : ar[countryCode]}`
    }));

  const Control = ({ children, ...props }: any) => {
    const style = {
      cursor: 'pointer',
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      justifyContent: 'center',
      height: '45px'
    };

    return (
      <components.Control {...props}>
        <div style={style}>
          <ImageWrapper
            width={20}
            height={20}
            alt="Country Flag"
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
          />
          {children}
        </div>
      </components.Control>
    );
  };

  return (
    <Dropdown locale={locale} isInvalid={touched && error} isPhone={true}>
      <Select
        aria-labelledby="aria-label"
        inputId="aria-phone-input"
        options={countryOptions}
        name="country-select"
        components={{ Control }}
        {...(isClosed && { menuIsOpen: false })}
        onChange={(selected: any) => {
          resetInput();
          setCountry(selected.value);
        }}
        className="select select-country"
        classNamePrefix="select"
        value={countryOptions.find(
          countryOption => countryOption.value === country
        )}
      />
    </Dropdown>
  );
};

export default CountrySelect;
