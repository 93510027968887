import { useEffect } from 'react';
import { initializeCompareState } from '../../../redux/slices/compareSlice';
import { useDispatch, useSelector } from 'react-redux';

const useMaintainCompareState = () => {
  const {
    compounds_ids,
    properties_ids,
    compare_type,
    compare_count,
    show_popup,
    show_compare_tooltip
  } = useSelector((state: any) => state.compare);

  const dispatch = useDispatch();

  useEffect(() => {
    const consumeCompareState = () => {
      const capturedCompareState = localStorage?.getItem('compare_state');

      dispatch(initializeCompareState(JSON.parse(capturedCompareState!)));

      localStorage.removeItem('compare_state');
    };
    if (localStorage?.getItem('compare_state')) {
      consumeCompareState();
    }
  }, []);

  useEffect(() => {
    const captureCompareState = () => {
      localStorage.setItem(
        'compare_state',
        JSON.stringify({
          compounds_ids,
          properties_ids,
          compare_type,
          compare_count,
          show_popup,
          show_compare_tooltip
        })
      );
    };
    window.addEventListener('pagehide', captureCompareState);

    return () => {
      window.removeEventListener('pagehide', captureCompareState);
    };
  }, [
    compounds_ids,
    properties_ids,
    compare_count,
    compare_type,
    show_popup,
    show_compare_tooltip
  ]);
};

export default useMaintainCompareState;
