import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import Link from 'next/link';
import Button from './Button';
import { useSelector } from 'react-redux';
import { theme } from '../../styles/theme';
import useCookieConsent from '../../hooks/CookieConsent';
import { useRouter } from 'next/router';

const CookieConsent = () => {
  const { t } = useTranslation('common');

  const webview = useSelector((state: any) => state.app.webview);

  const { locale } = useRouter();

  const { showConsent, isEUUser, rejectConsent, confirmDisclaimer } =
    useCookieConsent();

  return showConsent && !webview ? (
    <Container locale={locale}>
      <div className="inner-content">
        <div className="disclaimer">
          {t('consent_disclaimer')}{' '}
          {isEUUser && (
            <>
              {t('click_here_to')}
              <button onClick={rejectConsent} className="reject-consent">
                {t('reject')}
              </button>
            </>
          )}
        </div>

        <div className="action-btns">
          <Link
            title="privacy policy"
            href="/privacy-policy#cookies"
            className="learn-more"
            onClick={() => confirmDisclaimer('learn_more')}
          >
            {t('privacy_policy')}
          </Link>
          <Button
            backgroundColor={theme.light.colors.primaryBlue}
            border="none"
            color="white"
            fontSize="14px"
            onClick={() => confirmDisclaimer('confirm')}
            padding="8px 0"
            text={t('ok_got_it')}
            btnClass="got-it"
            containerClass="got-it-container"
          />
        </div>
      </div>
    </Container>
  ) : null;
};

const Container = styled.div<any>`
  background-color: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 16px;
  z-index: 999;
  border-radius: ${props => props.theme.borderRadius};
  width: 350px;
  align-self: flex-end;
  margin-inline-end: 40px;
  ${props => (props.locale === 'en' ? 'right: 0;' : 'left: 0;')}
  position: absolute;
  pointer-events: all;
  .inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    .disclaimer {
      color: ${props => props.theme.light.colors.black};
      font-family: materialRegular;
      font-size: ${props => props.theme.font.sizes.l};

      button {
        background: none;
        outline: none;
        border: none;
        color: #015c9a;
        cursor: pointer;
        text-decoration: underline;
        font-family: materialRegular;
        font-size: 16px;
      }
    }
    .action-btns {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100%;
      .learn-more,
      .got-it-container {
        font-family: materialMedium;
        border-radius: ${props => props.theme.borderRadius};
        height: 40px;
        width: 50%;
        font-size: ${props => props.theme.font.sizes.s};
        &.learn-more {
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #015c9a;
          outline: none;
          background-color: transparent;
          color: #015c9a;
        }

        .got-it {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    position: static;
    margin-inline-end: 0;
    margin-bottom: 0;
    width: 100%;
  }
`;
export default CookieConsent;
