import { OptionListValues } from './OptionListValues';
import { SingleValue } from './SingleValue';

interface InputType {
  min: number;
  max: number;
}

export class RangeOptionListValues extends OptionListValues {
  constructor(public inputRange: InputType) {
    super();
    this.inputRange = inputRange;
    this.createOptions();
  }

  // range input like min max like bedrooms to same results but from min to max

  createOptions = (): void => {
    if (!this.inputRange) return;
    const optionResults = [];
    for (
      let option = this.inputRange.min;
      option <= this.inputRange.max;
      ++option
    ) {
      const inputValue = Object.create(SingleValue);
      inputValue.constructor(option, {
        displayedValue: this.createDisplayedValue(option)
      });
      optionResults.push(inputValue);
    }
    this.outputOptions = optionResults;
  };
}
