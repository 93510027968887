import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

interface filterProps {
  selectedTab: 'areas' | 'compounds';
  setSelectedTab: (tab: 'areas' | 'compounds') => void;
}

const LocationFilterTabs = ({
  selectedTab,
  setSelectedTab
}: filterProps): JSX.Element => {
  const { t } = useTranslation('search');

  return (
    <Container>
      <button
        onClick={() => setSelectedTab('areas')}
        className={`location-btn ${selectedTab == 'areas' ? 'active' : ''}`}
        value={'area'}
      >
        {t('area')}
      </button>
      <button
        onClick={() => setSelectedTab('compounds')}
        className={`location-btn ${selectedTab == 'compounds' ? 'active' : ''}`}
        value={'compound'}
      >
        {t('compound')}
      </button>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  button {
    width: 50%;
    padding: 8px;
    border: none;
    background: none;
    border-bottom: 3px solid #d1d4d9;
    color: #697586;
    font-size: 14px;
    font-family: materialMedium;
    cursor: pointer;

    &.active {
      color: #000;
      border-bottom: 3px solid #015c9a;
    }
  }
`;

export default LocationFilterTabs;
