export enum WhatsappNumber {
  BLOG_WHATSAPP_NUMBER = '01069275839',
  DIRECT_WHATSAPP_NUMBER = '01021199808',
  GOOGLE_ORGANIC_WHATSAPP_NUMBER = '01029208551',
  GOOGLE_CPC_WHATSAPP_NUMBER = '01068888245',
  FACEBOOK_PAID_WHATSAPP_NUMBER = '01022088888',
  LANDING_PAGE_WHATSAPP_NUMBER = '01033339392'
}

export enum EntityTypes {
  PROPERTY = 'property',
  SUPER_COMPOUND = 'super_compound',
  COMPOUND = 'compound',
  SUPER_AREA = 'super_area',
  AREA = 'area',
  DEVELOPER = 'developer',
  LAUNCH = 'launch',
  SAHEL = 'sahel',
  NAWY_NOW = 'nawy-now'
}

export enum FormTypes {
  ORGANIC_FORM = 'organic_form',
  FREELANCER = 'freelancer',
  TOP_COMPOUNDS = 'topCompounds',
  ELITE_COMPOUNDS = 'eliteCompounds',
  SELL_PROPERTY = 'sell_property',
  CITYSCAPE = 'cityscape',
  PRIME_COMPOUNDS = 'primeCompounds',
  PARTNERS = 'nawyPartners',
  NAWY_NOW = 'nawyNow'
}

export enum CTATypes {
  PROPERTY = 'property',
  COMPOUND = 'compound',
  AREA = 'area',
  CITYSCAPE = 'cityscape',
  CITYSCAPE_COMPOUND = 'cityscape_compound',
  LAUNCH = 'launch',
  LANDING_PAGE = 'landing_page',
  MOENGAGE = 'moengage',
  SAHEL = 'sahel',
  NAWY_NOW = 'nawy-now',
  OFFERS = 'offers'
}
