type PropsType = {
  stroke: string;
  styles?: any;
};
const PrevArrowIcon = ({ stroke, styles }: PropsType) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 19 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.981 31.1765L3.90246 17.4382L15.981 3.69988"
        stroke={stroke}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PrevArrowIcon;
