import { useRouter } from 'next/router';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Sheet, SheetRef } from 'react-modal-sheet';
import { resetFilter } from '../../../../../helpers/search-filters';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import SelectFilterContainer from '../select-filter/SelectFilterContainer';
import { FilterStyles } from '../FilterWrapper';
import SearchIcon from '../../../../../new-components/svg-icons/SearchIcon';
import debounce from '../../../../search/utils';
import { useSelector } from 'react-redux';
import Modal from '../../../../../new-components/mobile/Modal';
import {
  FilterMultiSelectTypes,
  SearchFiltersActionTypes
} from '../../../../../reducers/search-filters/actions';
import { updateQuery } from '../../../../../helpers/common';
import CloseIcon from '../../../../../new-components/svg-icons/CloseIcon';

interface SearchMenuProps {
  filterName: string;
  options: any;
  type: any;
  setAreasDevelopersMenu: Dispatch<SetStateAction<boolean>>;
}

const SearchMenu = ({
  filterName,
  options,
  type,
  setAreasDevelopersMenu
}: SearchMenuProps) => {
  const { locale } = useRouter();
  const { searchFiltersDispatch, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const { t } = useTranslation(['common', 'search']);
  const router = useRouter();
  const sheetRef = useRef<SheetRef>();
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleRemoveValue = (value: number) => {
    let values = selectedOptions
      ?.filter((option: { value: number }) => option.value !== value)
      .map((option: any) => option.value);
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
      payload: {
        name: filterName as FilterMultiSelectTypes,
        value: values
      }
    });
    if (values.length === 0) {
      updateQuery(router, undefined, [filterName] as any);
    } else {
      updateQuery(
        router,
        [{ name: filterName, value: values.toString() }],
        undefined
      );
    }
  };
  useEffect(() => {
    let selectedOptions = searchFiltersState.searchFilters?.[
      filterName as 'developers' | 'areas'
    ]
      ?.map((optionId: number) =>
        options.find((option: { value: number }) => option.value === optionId)
      )
      .filter(Boolean);

    setSelectedOptions(selectedOptions);
  }, [searchFiltersState.searchFilters]);

  useEffect(() => {
    document.querySelector('.toolbar')?.classList.add('hide');
    return () => {
      document.querySelector('.toolbar')?.classList.remove('hide');
    };
  }, []);

  const debouncedResults = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 500),
    []
  );

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouncedResults(e.currentTarget.value);
  };
  const filteredOptions = options?.filter((option: any) =>
    option?.options?.displayedValue
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return isMobile ? (
    <Sheet
      ref={sheetRef}
      isOpen={true}
      onClose={() => setAreasDevelopersMenu(false)}
    >
      <Sheet.Container
        style={{
          // padding: '8px 16px 24px 16px',
          backgroundColor: '#FBFCFE'
        }}
      >
        <Sheet.Header style={{ paddingTop: '8px' }} />

        <Sheet.Content
          style={{
            width: '100%',
            direction: locale == 'ar' ? 'rtl' : 'ltr'
          }}
        >
          <Sheet.Scroller
            draggableAt="both"
            style={{
              position: 'relative',
              gap: '16px',
              direction: locale === 'ar' ? 'rtl' : 'ltr'
            }}
            className="hide-scrollbar"
            draggable
          >
            <Container locale={locale}>
              <div className="filter-header">
                <div className="filter-title">
                  {t(`filter_by`, { ns: 'search' })} {t(`${type}`)}
                </div>

                <div className="filter-reset">
                  <button
                    className="reset-btn"
                    onClick={() => {
                      resetFilter(type, searchFiltersDispatch, router);
                    }}
                  >
                    {t('reset')}
                  </button>
                </div>
              </div>
              <div className="search-bar">
                <SearchIcon width="17.5px" height="17.5px" fill="#788496" />

                <Input
                  placeholder={t(`${type}_search`, { ns: 'search' })}
                  onChange={handleInputChange}
                />
              </div>
              <div className="options-container">
                <SelectFilterContainer
                  filterOptions={filteredOptions}
                  filterType={type as SearchFiltersTypes}
                  filterName={filterName}
                  filterStyle={FilterStyles.CHECKBOX}
                />
              </div>
              <div className="add-btn-container">
                <button
                  className="add-btn"
                  onClick={() => setAreasDevelopersMenu(false)}
                >
                  <span>{t('add')}</span>
                </button>
              </div>
            </Container>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  ) : (
    <Modal
      isOpen={true}
      closeFn={() => setAreasDevelopersMenu(false)}
      withCloseBtn={true}
      modalWidth="40vw"
    >
      <Container locale={locale}>
        <div className="filter-header">
          <div className="filter-title">
            {t(`add_more`, { ns: 'common' })} {t(`${type}`)}
          </div>
        </div>
        <div className="search-bar">
          <SearchIcon width="17.5px" height="17.5px" fill="#788496" />

          <Input
            placeholder={t(`${type}_search`, { ns: 'search' })}
            onChange={handleInputChange}
          />
        </div>
        {selectedOptions?.length > 0 && (
          <div className="selected-values hide-scrollbar">
            {selectedOptions.slice(0, 4).map((option: any, index: number) => {
              return index === 3 ? (
                <span className={'option-button'}>
                  {`${selectedOptions.length - 3} ${t('more')}`}
                </span>
              ) : (
                <button
                  key={option.value}
                  className={'option-button'}
                  onClick={() => handleRemoveValue(option.value)}
                >
                  {t(option.options.displayedValue)}
                  <CloseIcon styles={{ width: '12px', height: '12px' }} />
                </button>
              );
            })}
          </div>
        )}

        <div className="options-container hide-scrollbar">
          <SelectFilterContainer
            filterOptions={filteredOptions}
            filterType={type as SearchFiltersTypes}
            filterName={filterName}
            filterStyle={FilterStyles.CHECKBOX}
          />
        </div>
        <div className="add-btn-container">
          <button
            className="add-btn"
            onClick={() => setAreasDevelopersMenu(false)}
          >
            {t('add') + ' ' + t(`${type}`)}
          </button>
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  position: relative;
  overflow-y: hidden;
  .filter-header {
    display: flex;
    align-items: center;
    .filter-title {
      color: #1a2028;
      font-family: materialBold;
      font-size: 21px;
    }
    .reset-btn {
      background: none;
      border: none;
      outline: none;
      color: #1e6dac;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .selected-values {
    display: flex;
    gap: 8px;
    overflow-x: auto;
    .option-button {
      font-family: materialRegular;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;
    }
  }
  .search-bar {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 8px 16px;
    gap: 8px;
    border: 1px solid #d3dbe6;
    border-radius: 12px;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: auto;
    .input-container {
      padding: 16px 0px 8px 0px;
      border-bottom: 0.5px solid rgba(211, 219, 230, 0.5);
      &:last-child {
        border-bottom: 0px;
        padding-bottom: 60px;
      }
    }
  }

  .add-btn-container {
    width: 100%;
    padding: 8px 16px 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    border: none;
    outline: none;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: #015c9a;
      outline: none;
      border: none;
      border-radius: 10px;
      color: #fff;
      font-family: materialMedium;
      font-size: 14px;
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    position: unset;
    padding: unset;
    padding: 0px 16px 24px 16px;
    .filter-header {
      justify-content: space-between;
    }

    .search-bar {
      background-color: #f7f9fd;
    }

    .options-container {
      margin-bottom: 80px;
      height: auto;
      max-height: unset;
      overflow-y: unset;
      .input-container {
        position: relative;
        input {
          position: absolute;
          right: ${props => (props.locale === 'en' ? '0px' : '')};
          left: ${props => (props.locale === 'ar' ? '0px' : '')};
        }
      }
    }

    .add-btn-container {
      position: fixed;
      align-items: center;
      /* flex: 1; */

      border-top: 4px solid transparent;
      padding: 8px 16px 24px;
      min-width: 100%;
      bottom: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        min-width: 100%;
        height: 50px;
        background-color: #015c9a;
        outline: none;
        border: none;
        border-radius: 10px;
        color: #fff;
        font-family: materialMedium;
        font-size: 14px;
      }
    }
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  user-select: none;
  width: 100%;

  ::placeholder {
    color: #5b6573;
    font-size: 14px;
    font-family: materialRegular;
  }

  :focus {
    outline: none;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    background-color: #f7f9fd;
  }
`;

export default SearchMenu;
