import styled from 'styled-components';
import Modal from '../../../../new-components/mobile/Modal';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { hidePopup } from '../../../../redux/slices/compareSlice';
import { useEffect } from 'react';
import { getFirstPathSegment, logEvent } from '../../../../new-lib/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { AdjustLinkService } from '../../../../new-lib/dynamic-link';

const DownloadAppPopup = () => {
  const showPopup = useSelector((state: any) => state.compare.show_popup);
  const { t } = useTranslation('common');
  const { asPath } = useRouter();

  const dispatch = useDispatch();

  const closePopup = () => {
    dispatch(hidePopup());
  };
  const getNawyApp = () => {
    logEvent.click('cmpr_get_app', getFirstPathSegment(asPath), 'compare');

    AdjustLinkService.handleOpenApp(asPath);
    dispatch(hidePopup());
  };

  useEffect(() => {
    closePopup();
  }, [asPath]);

  return (
    <PopupContainer>
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal
          isOpen={showPopup}
          closeFn={() => {}}
          title={t('download_nawy_app')}
          withCloseBtn={false}
        >
          <div className="popup-content">
            <p>{t('comparison_max_limit_text')}</p>
            <button className="get-app" onClick={getNawyApp}>
              {t('get_app')}
            </button>
            <button
              className="skip"
              onClick={() => {
                logEvent.click(
                  'cmpr_skip_download_app',
                  getFirstPathSegment(asPath),
                  'compare'
                );

                closePopup();
              }}
            >
              {t('skip')}
            </button>
          </div>
        </Modal>
      </div>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  .modal-header {
    justify-content: center !important;
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 5px;
    padding: 0px 24px;

    p {
      font-size: ${props => props.theme.font.sizes.s};
      font-family: materialRegular;
      color: #4b535f;
      text-align: center;
    }

    button {
      width: 100%;
      border-radius: 8px;
      height: 44px;
      font-size: ${props => props.theme.font.sizes.xs};
      font-family: materialSemiBold;
      padding: 0 16px;

      &.get-app {
        background-color: #015c9a;
        border: none;
        color: #f9fafb;
      }

      &.skip {
        height: fit-content;
        background-color: transparent;
        border: none;
        color: #4b535f;
        text-decoration: underline;
      }
    }
  }
`;
export default DownloadAppPopup;
