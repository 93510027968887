import { useTranslation } from 'next-i18next';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import styled from 'styled-components';
interface SingleSelectpropsTypes {
  options: InputOption[];
  selectedValues: string | number | undefined;
  shapeClass: string;
  onChange: any;
  isMoveNowFiltered?: boolean;
}
interface inputPropType {
  inputOption: InputOption;
}
const SingleSelectPresentaion = ({
  options,
  selectedValues,
  shapeClass,
  onChange,
  isMoveNowFiltered
}: SingleSelectpropsTypes) => {
  const { t } = useTranslation('search');
  const Input = ({ inputOption }: inputPropType) => {
    const addClasses = (): string => {
      if (selectedValues) {
        const isSelected: boolean = selectedValues == inputOption.value;

        if (isSelected) {
          return `${shapeClass} shape selected ${
            isMoveNowFiltered ? 'dimmed' : ''
          }`;
        } else {
          return `${shapeClass} shape ${isMoveNowFiltered ? 'dimmed' : ''}`;
        }
      } else {
        return `${shapeClass} shape`;
      }
    };
    return (
      <div
        className={addClasses()}
        onClick={() => handleInputStatues(inputOption.value)}
      >
        {t(inputOption.options.displayedValue)}
      </div>
    );
  };
  const handleInputStatues = (inputId: string | number) => {
    if (inputId == selectedValues) {
      onChange('');
    } else {
      onChange(inputId);
    }
  };
  return (
    <Container>
      {options.map(curr => (
        <Input inputOption={curr} key={curr.value} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  .circleShape {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background: ${props => props.theme.light.colors.white};
    color: ${props => props.theme.light.colors.darkGrey};
    border: 1px solid ${props => props.theme.light.colors.white};
    font-size: ${props => props.theme.font.sizes.xxs};
    font-family: materialMedium;
  }
  .shape {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.light.colors.white};
    color: ${props => props.theme.light.colors.darkGrey};
    border: 1px solid ${props => props.theme.light.colors.white};
    font-size: ${props => props.theme.font.sizes.l};
    padding: 5px 8px;
    &:hover {
      color: ${props => props.theme.light.colors.secondary};
      border: 1px solid ${props => props.theme.light.colors.secondary};
    }

    &.dimmed {
      pointer-events: none;
      background-color: #e0e0e0;
      color: #818181;
    }
  }
  .circleShape {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    font-size: ${props => props.theme.font.sizes.xxs};
  }

  .straightShape {
    border-radius: ${props => props.theme.borderRadius};
    font-size: ${props => props.theme.font.sizes.xxs};
  }
  .selected {
    background: ${props => props.theme.light.colors.secondary} !important;
    color: ${props => props.theme.light.colors.white} !important;
    border: 1px solid ${props => props.theme.light.colors.secondary} !important;
    &.dimmed {
      pointer-events: none;
    }
  }
`;

export default SingleSelectPresentaion;
