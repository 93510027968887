type PropsType = {
  styles?: any;
  fill?: string;
};

const CloseIcon = ({ styles, fill }: PropsType) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <rect
        width="3.05565"
        height="21.4462"
        rx="1.52783"
        transform="matrix(0.878753 0.477276 -0.596961 0.80227 13.3481 0.955078)"
        fill={fill || '#646464'}
      />
      <rect
        width="3.05569"
        height="21.4459"
        rx="1.52784"
        transform="matrix(0.87879 -0.477208 0.596889 0.802324 0.545898 2.41284)"
        fill={fill || '#646464'}
      />
    </svg>
  );
};

export default CloseIcon;
