import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { SearchSelection } from '../../../hooks/LocationFilter';

interface LocationResultsProps {
  searchResults: SearchSelection[];
  handleSelect: (selection: SearchSelection) => void;
  selectedIds: number[];
  handleRemove: (selection: SearchSelection) => void;
}

interface ResultProps {
  locale?: string;
}

const SearchLocationResults = ({
  searchResults,
  handleSelect,
  selectedIds,
  handleRemove
}: LocationResultsProps) => {
  const handleClick = (result: SearchSelection) => {
    if (selectedIds.includes(result.id)) {
      handleRemove(result);
    } else {
      handleSelect(result);
    }
  };

  const { t } = useTranslation('common');
  return (
    <Container>
      {searchResults?.map(result => (
        <ResultContainer
          onClick={() => handleClick(result)}
          data-cy="search-input-results"
          className="location-result"
          key={result.id}
        >
          <input
            type="checkbox"
            name="location-results"
            id={`${result.id}`}
            checked={selectedIds.includes(result.id)}
          />
          <div className="text-3">{result?.name}</div>
        </ResultContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .headline-2 {
    margin-bottom: 12px;
  }

  .location-result {
    &:not(:last-child) {
      border-bottom: 1px solid #d3dbe6;
    }
  }
`;

const ResultContainer = styled.div<ResultProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 40px;
  align-items: center;

  .result-left {
    display: flex;
    align-items: center;
    min-width: 0;
  }

  .text-3 {
    color: ${props => props.theme.light.colors.black};
    font-size: 14px;
    font-family: materialMedium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline-start: 5px;
  }

  .result-right {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .cityscape-label {
    border: 1px solid transparent;
    border-radius: 35px;
    padding: 3px 15px;
    background-color: #ff9108;
    color: white;
    font-size: ${props => props.theme.font.sizes.xxs};
    font-family: materialMedium;
    text-align: center;
    white-space: nowrap;
    margin-inline-end: 8px;
  }
  .icon-side {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 19px;
      height: 19px;
      path {
        fill: #646464;
      }
    }
  }

  span:not(.location) {
    color: ${props => props.theme.light.colors.black};
    background-color: #f0f0f0;
    padding: 2.5px 8px;
    font-size: ${props => props.theme.font.sizes.xxs};
    font-family: materialMedium;
    min-width: fit-content;
    border-radius: 35px;
    text-transform: capitalize;
  }

  &.compound-result {
    height: 50px;
    span {
      border-radius: 4px;
    }

    .image-side {
      position: relative;
      min-width: 40px;
      height: 40px;
    }

    span.location {
      font-size: 11px;
      color: #646464;
      display: block;
    }

    svg {
      height: 8px;
      width: 6px;
      transform: ${props => (props.locale == 'en' ? 'rotate(180deg)' : '')};
      margin-inline-end: 8px;
      path {
        stroke: #666666;
      }
    }
  }
`;
export default SearchLocationResults;
