import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { commifyNumber, unCommifyNumber } from '../lib/helpers';
import { InputOption } from '../new-lib/types/search-filters';

interface inputProps {
  width: number;
  list?: Array<InputOption>;
  min: number | string;
}

interface childProps {
  name?: string;
  min: number | string;
  width: number;
  initValue: number | undefined;
  placeholder: string;
  list?: InputOption[];
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onSelect: (e: React.FormEvent<HTMLInputElement>) => void;
}

const NumberInput = ({
  placeholder,
  name,
  width,
  list,
  min,
  initValue,
  onBlur,
  onSelect
}: childProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<number | string | undefined>(
    initValue
  );
  const [listOpen, setListOpen] = useState<boolean>(false);
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setListOpen(false);
    setInputValue(
      commifyNumber(
        unCommifyNumber(
          e.currentTarget.value
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*)\./g, '$1')
        )
      )
    );
  };

  const handleSelect = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValue(commifyNumber(e.currentTarget.value));
    onSelect(e);
    setListOpen(false);
  };

  const handleBlur = (e: React.FormEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    } 
    if (!e.currentTarget.value && initValue) {
      onSelect(e);
    }
    setTimeout(() => setListOpen(false), 200);
  };

  useEffect(() => {
    if (initValue) {
      setInputValue(commifyNumber(initValue));
    } else {
      if (inputValue) {
        setInputValue('');
      }
    }
  }, [initValue]);

  return (
    <>
      <Input width={width} list={list} min={min}>
        <input
          name={name}
          value={inputValue}
          placeholder={placeholder}
          type="text"
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={() => setListOpen(true)}
          autoComplete="off"
        />
        {list && listOpen ? (
          <ul>
            {list.map((item, index) => (
              <li
                className="text-1"
                key={index}
                value={item.value}
                onClick={(e: any) => handleSelect(e)}
              >
                {item.options.displayedValue}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </Input>
    </>
  );
};

const Input = styled.div<inputProps>`
  max-width: ${props => props.width};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  input {
    height: 35px;
    width: 100%;
    outline: none;
    border: 1px solid #e3e3e3;
    border-radius: ${props => props.theme.borderRadius};
    padding: 8px;
    color: ${props => props.theme.light.colors.black};

    &:focus {
      border: 1px solid ${props => props.theme.light.colors.secondary};
    }
  }
  ul {
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: opacity 0.3s ease-out;
  }
  ul {
    height: auto;
    opacity: 1;
    pointer-events: all;
    background-color: #fff;
    padding: 16px;
    border-radius: ${props => props.theme.borderRadius};
    width: 100%;
    border: 1px solid #e3e3e3;
    margin-top: 4px;

    li {
      margin-bottom: 5px;
      cursor: pointer;
      font-size: ${props => props.theme.font.sizes.s};
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: ${props => props.theme.light.colors.secondary};
      }
    }
  }
`;
export default NumberInput;
