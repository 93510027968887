const InvalidIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_5412_2347)">
        <path
          d="M7.99992 14.6665C11.6818 14.6665 14.6666 11.6818 14.6666 7.99986C14.6666 4.31796 11.6818 1.33319 7.99992 1.33319C4.31802 1.33319 1.33325 4.31796 1.33325 7.99986C1.33325 11.6818 4.31802 14.6665 7.99992 14.6665Z"
          stroke="#DA3E33"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 5.33319V7.99986"
          stroke="#DA3E33"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 10.6665H8.00667"
          stroke="#DA3E33"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5412_2347">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InvalidIcon;
