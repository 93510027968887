type PropsType = {
  styles?: object;
  fill?: string;
  stroke?: string;
};

const ToggleArrowIcon = ({ styles, fill, stroke }: PropsType) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      stroke={stroke}
      style={styles}
      fill={fill || 'white'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.51953L4.5 4.51953L1 1.51953"
        stroke="#00BDCF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ToggleArrowIcon;
