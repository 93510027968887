export const theme: any = {
  light: {
    backgroundColor: '#ffffff',
    colors: {
      primaryOrange: '#FF5E00',
      primaryBlue: '#1E4164',
      secondaryBlue: '#0C314E',
      secondary: '#1E4164',
      // secondary: '#00BDCF',
      // tertiary: '#7DCBC1',
      tertiary: '#1E4164',
      black: '#212427',
      darkGrey: '#666666',
      midGray: `#646464`,
      veryLightGrey: '#C8C8C8',
      lightGrey: '#FAFAFA',
      whitish: '#E4EBF2',
      white: '#FFFFFF',
      skyBlue: 'rgba(0, 189, 207, 0.1)',
      grey: '#8E8E8E'
    },
    borderColors: {
      primary: '#E6E6E6',
      secondary: '#EDEDED'
    },
    hoverColors: {
      primaryOrange: '#FF8036',
      primaryBlue: '#165688',
      secondary: '#D4DBE2',
      midGray: ' #C2C2C2'
    }
  },
  font: {
    sizes: {
      xxs: '12px',
      xs: '13px',
      s: '14px',
      m: '16px',
      l: '16px',
      xl: '18px',
      xxl: '20px',
      xxxl: '22px',
      xxxxl: '26px',
      xxxxxl: '28px',
      header: '40px'
    }
  },
  breakPoints: {
    desktop: {
      min: '1300px',
      mid: '1600px',
      max: '1920px'
    },
    tablet: {
      min: '1023px',
      max: '1365px'
    },
    mobile: {
      min: '320px',
      max: '990px'
    }
  },
  borderRadius: '8px'
};
