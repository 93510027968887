import { WhatsappNumber } from '../new-lib/constants/common';
import sbjs from 'sourcebuster';
import Cookies from 'universal-cookie';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import axios from 'axios';
import { searchFiltersInitialState } from '../reducers/search-filters/reducer';

const DYNAMIC_LINK = process.env.NEXT_PUBLIC_DYNAMIC_LINK;
const HOST = process.env.NEXT_PUBLIC_HOST;

interface Multipliers {
  K: 1000;
  M: 1000000;
  B: 1000000000;
}

const multipliers: Multipliers = {
  K: 1000,
  M: 1000000,
  B: 1000000000
};

export const getImagePath = (image_url?: string | null): string => {
  if (!image_url) {
    return '/assets/images/common/broken.webp';
  }

  if (image_url && image_url.includes('http')) {
    return image_url;
  }
  return process.env.NEXT_PUBLIC_IMAGE_DOMAIN + image_url;
};

export const getCompressedImage = (
  image: string,
  mediumResolution?: boolean
) => {
  if (!image || !image?.includes('amazonaws')) {
    return '/assets/images/common/broken.webp';
  }

  const prefix = image.lastIndexOf('/') + 1;
  const resolution = mediumResolution ? 'medium_' : 'low_';
  return image.slice(0, prefix) + resolution + image.slice(prefix);
};

export const getReadyBy = (readyBy: any): any => {
  let readyByDate = readyBy && new Date(readyBy);
  let thisDay = new Date();

  if (readyByDate?.getTime() < thisDay.getTime()) {
    return 'delivered';
  } else {
    return readyByDate?.getFullYear();
  }
};

export const commifyNumber = (x: number): string => {
  if (x == null) {
    return '';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getWhatsappNumber = (referer?: string) => {
  const isBlogLead = () => {
    return referer && referer.includes('blog');
  };
  const isDirectLead = () => {
    return sbjs.get && sbjs.get.current.src === '(direct)';
  };
  const isGoogleOrganicLead = () => {
    return (
      sbjs.get &&
      sbjs.get.current?.src === 'google' &&
      sbjs.get.current?.mdm === 'organic'
    );
  };
  const isGooglePaidLead = () => {
    const leadHasSrc = () => {
      return sbjs.get.current?.src === 'google';
    };
    const leadHasMdm = () => {
      return sbjs.get.current?.mdm === 'cpc';
    };
    const leadHasCmp = () => {
      const isGoogleCmp = () => {
        return (
          sbjs.get.current?.cmp?.toLowerCase()?.includes('discover') ||
          sbjs.get.current?.cmp?.toLowerCase()?.includes('display') ||
          sbjs.get.current?.cmp?.toLowerCase()?.includes('remarketing')
        );
      };
      return sbjs.get.current?.cmp && isGoogleCmp();
    };

    const leadHasSrcAndMdm = () => {
      return leadHasSrc() && leadHasMdm();
    };
    const leadHasSrcAndCmp = () => {
      return leadHasSrc() && leadHasCmp();
    };
    return sbjs.get && (leadHasSrcAndMdm() || leadHasSrcAndCmp());
  };

  const isFacebookLead = () => {
    return (
      sbjs.get &&
      (sbjs.get.current?.src?.toLowerCase() === 'facebook' ||
        sbjs.get.current?.src?.toLowerCase()?.includes('instagram'))
    );
  };

  if (isBlogLead()) {
    return WhatsappNumber.BLOG_WHATSAPP_NUMBER;
  }
  if (isDirectLead()) {
    return WhatsappNumber.DIRECT_WHATSAPP_NUMBER;
  } else if (isGoogleOrganicLead()) {
    return WhatsappNumber.GOOGLE_ORGANIC_WHATSAPP_NUMBER;
  } else if (isGooglePaidLead()) {
    return WhatsappNumber.GOOGLE_CPC_WHATSAPP_NUMBER;
  } else if (isFacebookLead()) {
    return WhatsappNumber.FACEBOOK_PAID_WHATSAPP_NUMBER;
  }

  return process.env.NEXT_PUBLIC_WHATSAPP_NUMBER || '01065888849';
};

export const objToQuery = (obj: any): string => {
  const cookies = new Cookies();
  const arr = [];
  arr.push(`token=${cookies.get('token')}`);
  for (const [key, value] of Object.entries(obj)) {
    if (!value) continue;
    arr.push(`${key}=${value}`);
  }
  return encodeURI(arr.join('&'));
};

export const convertStringArrayToNumberArray = (strArr: string[]) => {
  let numberArr: number[] = [];
  strArr.forEach(item => {
    const parsedItem = parseInt(item);
    if (!isNaN(parsedItem)) numberArr.push(parsedItem);
  });
  return numberArr;
};

export const prettifyWords = (word: string): string => {
  const res = word.toLowerCase().replace(/_/g, ' ');
  return res.replace(/^./, res[0].toUpperCase());
};

export const removeNullUndefinedEmptyFromObject = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value != undefined && value != null
    )
  );
};

export const handleOpenApp = (path?: string) => {
  const pageLocation = path ? `_${getPageLocation(path)}` : '';
  const utmCampaign =
    sbjs.get?.current?.cmp === '(none)'
      ? ''
      : `&utm_campaign=${sbjs.get?.current?.cmp}`;
  const urlPath = path ?? '';

  let mobileAppId = '';
  if (HOST!.includes('nawy')) {
    mobileAppId = `&apn=com.cooingestate.cooing_app&isi=1571474485&ibi=com.cooingestate.app${utmCampaign}&utm_medium=${sbjs.get.current.mdm}${pageLocation}&utm_source=${sbjs.get?.current?.src}&efr=1`;
  } else {
    mobileAppId = `&apn=com.cooingestate.cooing_ap.stg&isi=1571474485&ibi=com.cooingestate.app.stg${utmCampaign}&utm_medium=${sbjs.get.current.mdm}${pageLocation}&utm_source=${sbjs.get?.current?.src}&efr=1`;
  }

  const linkQuery = `?link=${HOST}${urlPath?.replace('?', '&')}${mobileAppId}`;

  window.location.href = `${DYNAMIC_LINK}${linkQuery}`;
};

export const handleHorizontalScroll = (
  direction: 'left' | 'right',
  containerRef: React.RefObject<HTMLDivElement>,
  offset: number
) => {
  const { current } = containerRef;
  const scrollAmount = direction === 'left' ? -offset : offset;
  if (current) {
    current.scrollLeft += scrollAmount;
  }
};

export const hasOverflowingChildren = (
  ref: React.RefObject<HTMLDivElement>
) => {
  let offsetWidth = ref.current?.offsetWidth;
  let scrollWidth = ref.current?.scrollWidth;
  return (
    typeof offsetWidth == 'number' &&
    typeof scrollWidth == 'number' &&
    offsetWidth < scrollWidth
  );
};

export const updateQuery = (
  router: NextRouter,
  propertiesToAdd?: { [key: string]: any }[],
  keysToDelete?: string[]
) => {
  const { query, push } = router;
  keysToDelete?.forEach(key => {
    if (key in query) delete query[key];
  });

  propertiesToAdd?.forEach(property => {
    query[property.name] = property.value;
  });
  delete query.device;
  Object.keys(query).forEach(queryParam => {
    if (router.pathname.includes(queryParam)) {
      delete query[queryParam];
    }
  });

  push(
    {
      query,
      pathname: router.asPath
        .split('?')[0]
        .replace('(/desktop|/mobile|/undefined)', '')
    },
    undefined,
    { shallow: true }
  );
};

export const getPhoneNumber = (referer?: any) => {
  if (referer && referer.includes('blog')) return '01069275839';
  if (
    sbjs.get &&
    sbjs.get?.current?.src === 'Facebook' &&
    sbjs.get?.current?.mdm === 'AB_test'
  )
    return '01021199808';
  else if (
    sbjs.get &&
    sbjs.get?.current?.src === 'google' &&
    sbjs.get?.current?.mdm === 'organic'
  )
    return '01029208551';
  else if (
    sbjs.get &&
    sbjs.get?.current?.src === 'google' &&
    sbjs.get?.current?.mdm === 'cpc'
  )
    return '01068888245';
  return process.env.NEXT_PUBLIC_PHONE_NUMBER || '01065888849';
};

export const extractNumber = (input: string): number => {
  const value = input?.replace(/[^\d.]/g, '');
  const multiplier = input?.replace(/[\d.]/g, '');
  let result = parseFloat(value);

  if (multiplier in multipliers) {
    result *= multipliers[multiplier as keyof typeof multipliers];
  }

  return result;
};

export const getImages = (images: any[]) => {
  return images.map(image => {
    return getImagePath(image.image_path);
  });
};

export const truncateString = (str?: string, n: number = 300) => {
  if (!str) {
    return '';
  }
  if (str.length > n) {
    return str.slice(0, n) + '...';
  }
  return str;
};

export const containsAnyWord = (sentence: string, words: string[]) => {
  return words.some(word => sentence.includes(word));
};

export const getLocation = async () => {
  try {
    const response = await axios.get('https://api.country.is/');
    return response.data.country;
  } catch (error) {
    return 'EG';
  } finally {
    return 'EG';
  }
};

export const getRequest = (query: ParsedUrlQuery, url: string) => {
  if (query.property_slug) {
    return { property_id: (query.property_slug as string).split('-')[0] };
  } else if (query.compound_slug) {
    return { compound_id: (query.compound_slug as string).split('-')[0] };
  }
  return { url: url };
};

export const isPrime = (path: string) => {
  const paths = [
    '/top-compounds',
    '/prime-compounds',
    '/real-estate-360',
    '/nawy-partners'
  ];
  if (containsAnyWord(path, paths)) return true;
  return false;
};

export const splitQueryObject = (
  query: ParsedUrlQuery,
  maxChars: number = 40
): ParsedUrlQuery[] => {
  const result: ParsedUrlQuery[] = [];
  let currentObj: ParsedUrlQuery = {};

  for (const [key, value] of Object.entries(query)) {
    if (!(key in searchFiltersInitialState.searchFilters)) continue;
    const combinedKey = `${key}=${value}`;

    if (JSON.stringify(currentObj).length + combinedKey.length > maxChars) {
      result.push(currentObj);
      currentObj = {};
    }

    currentObj[key] = value;
  }

  if (Object.keys(currentObj).length > 0) {
    result.push(currentObj);
  }

  return result;
};

export const getPageLocation = (currentUrl: string): string => {
  const pages: { [key: string]: boolean } = {
    property: true,
    compound: true,
    search: true,
    'nawy-now': true,
    'top-compounds': true,
    'prime-compounds': true,
    favorites: true,
    'about-us': true,
    'real-estate-360': true,
    'nawy-partners': true,
    'sell-my-property': true,
    area: true,
    developer: true,
    buy: true,
    contact: true,
    'how-it-works': true,
    terms: true,
    'new-launches': true,
    cityscape: true,
    'sahel-map': true
  };
  const segments: string[] = currentUrl.split('/').filter(Boolean);
  let page: string | undefined;

  if (segments.includes('property')) {
    page = 'property';
  } else if (segments.includes('compound')) {
    page = 'compound';
  } else {
    page = segments.find((segment: string) => pages[segment]);
  }
  return page || 'home';
};

export const formatNumber = (num: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  });

  return formatter.format(num);
};

export const generateRandomId = () => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const injectComponentBetweenRows = (
  data: any,
  componentToInject: any,
  position: number
) => {
  let modifiedRows: any = [];
  modifiedRows.push(data.splice(0, position));
  modifiedRows.push(componentToInject);
  modifiedRows.push(data.splice(0, data.length));
  return modifiedRows;
};

// behavior param was added as 'smooth' scrolling is buggy on Safari IOS
// (An alternative would be to use polyfill library) such as:
//  https://www.npmjs.com/package/seamless-scroll-polyfill or https://github.com/iamdustan/smoothscroll
export const scrollToComponent = (
  idSelector: string,
  offset?: number,
  behavior?: ScrollBehavior
) => {
  const component = document.getElementById(idSelector);
  const containerPosition = component!.getBoundingClientRect().top;

  window.scroll({
    top: containerPosition + window.scrollY - (offset ?? 200),
    behavior: behavior ?? 'auto'
  });
};

export const isOnlyWhitespace = (str: string) => {
  // The regex /^\s*$/ checks if the string contains only whitespace characters
  return /^\s*$/.test(str);
};
