import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  searchResults: {
    isFetching: boolean;
    isError: boolean;
    loadedSearchResults: object[];
    loadedSearchResultsCount: number;
    loadedPropertyTypes: object[];
    loadedAmenities: object[];
    firstRenderSSR: boolean;
    ssrSearchType: string;
  };
}
const initialState: InitialState = {
  searchResults: {
    isFetching: false,
    isError: false,
    loadedSearchResults: [],
    loadedSearchResultsCount: 0,
    loadedPropertyTypes: [],
    loadedAmenities: [],
    firstRenderSSR: false,
    ssrSearchType: ''
  }
};

export const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState,
  reducers: {
    setSearchResults(state, action) {
      state.searchResults = action.payload;
    }
  }
});
export const { setSearchResults } = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
