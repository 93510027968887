import styled from 'styled-components';
import Slider from './slider-component/SliderPresentation';
import InputValue from './InputValue';
import { ChangeEvent } from 'react';

interface ContainerProps {
  filterOptions: any;
  range: number[];
  handleRangeChange: (values: number[]) => void;
  applyFilter: () => void;
  prefix?: string;
  step?: number;
  handleMinRangeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleMaxRangeChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RangeFilterPresentation = ({
  filterOptions,
  range,
  handleRangeChange,
  prefix,
  step,
  applyFilter,
  handleMinRangeChange,
  handleMaxRangeChange
}: ContainerProps) => {
  return (
    <Container>
      <div className="slider-container">
        <Slider
          rangeLimit={filterOptions}
          range={range}
          handleRangeChange={handleRangeChange}
          step={step}
          applyFilter={applyFilter}
        />
      </div>
      <div className="inputs-container">
        <InputValue
          prefix={prefix}
          value={range[0]}
          onChange={handleMinRangeChange}
          applyFilter={applyFilter}
        />
        <InputValue
          prefix={prefix}
          value={range[1]}
          onChange={handleMaxRangeChange}
          applyFilter={applyFilter}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .slider-container {
    width: calc(100% - 24px);
    margin: 0 auto;
  }

  .inputs-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;

    .input-wrapper {
      width: 50%;
      padding: 8px 16px;
      border-radius: 8px;
      outline: none;
      border: 1px solid #c6d0de;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      input {
        color: #1a2028;
        font-size: 14px;
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent;
      }
      span {
        color: #5b6573;
        font-size: 11px;
      }
    }
  }
`;

export default RangeFilterPresentation;
