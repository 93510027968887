import { InputOption } from '../../../../../new-lib/types/search-filters';
import { useContext } from 'react';
import styled from 'styled-components';
import CheckedIcon from '../../../../../new-components/svg-icons/CheckedIcon';
import { CheckBoxFilterTypes } from './CheckboxContainer';
import { useTranslation } from 'next-i18next';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { SearchContext, SearchContextInterface } from '../../../../search-new/context/SearchContext';

interface CheckBoxInputProps {
  option: InputOption;
  handleChange: (filterType: CheckBoxFilterTypes) => void;
}

interface CheckBoxPresentationProps {
  options: InputOption[];
  handleChange: (filterType: CheckBoxFilterTypes) => void;
}

const CheckBoxInput = ({ option, handleChange }: CheckBoxInputProps) => {
  const { t } = useTranslation('search');

  const { searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;

  const checked =
    option.filterType === SearchFiltersTypes.NAWY_NOW
      ? searchFiltersState.searchFilters[SearchFiltersTypes.NAWY_NOW]
      : searchFiltersState.searchFilters[
          option.filterType as CheckBoxFilterTypes
        ];

  return (
    <CheckboxInput
      onClick={() => {
        handleChange(option.filterType as CheckBoxFilterTypes);
      }}
    >
      <div className="checkbox">
        {checked && (
          <CheckedIcon
            styles={{ width: '14px', height: '14px' }}
            fill="#006694"
          />
        )}
      </div>
      <span>{t(option.options.displayedValue)}</span>
    </CheckboxInput>
  );
};

const CheckboxPresentation = ({
  options,
  handleChange
}: CheckBoxPresentationProps) => {
  return (
    <FilterContainer>
      {options.map((option: InputOption) => {
        return (
          <CheckBoxInput
            option={option}
            handleChange={handleChange}
            key={option.value.toString()}
          />
        );
      })}
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const CheckboxInput = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid #006694;
    background: #e6f0f4;
  }

  span {
    font-size: ${props => props.theme.font.sizes.s};
    font-family: materialSemiBold;
    color: ${props => props.theme.light.colors.black};
  }

  @media screen and (min-width: ${props =>
      props.theme.breakPoints.desktop.min}) {
    flex: unset;
  }
`;

export default CheckboxPresentation;
