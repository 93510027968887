import { ImageSize } from '../new-lib/types/common';
import { Image } from '../new-lib/types/entity';
import { getImagePath } from './common';

export const getImageSources = (images?: Image[]) => {
  return images && images.length > 0
    ? images.map(image => {
        return getImagePath(image.image_path);
      })
    : [];
};

export const getCoverPhoto = (images: Image[] | null) => {
  if (images) return images.find(image => image.cover_photo == true);
  return null;
};

export const getWebpSrc = (originalSrc: string, imageSize: ImageSize) => {
  return originalSrc
    .replace('s3.eu-central-1.amazonaws.com/', '')
    .replace('prod.images', 'prod-images')
    .replace('admin', 'processed')
    .replace(/\/[^\/]*$/, `/${imageSize ?? 'medium'}.webp`);
};
