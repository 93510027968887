import { Dispatch, SetStateAction } from 'react';
import Modal from './mobile/Modal';
import VerifyAgentComponent from './agent-verification/VerifyAgent';

interface AgentVerificationProps {
  showVerification: boolean;
  setShowVerification: Dispatch<SetStateAction<boolean>>;
}

const AgentVerificationModal = ({
  showVerification,
  setShowVerification
}: AgentVerificationProps) => {
  return (
    <Modal
      closeFn={() => setShowVerification(false)}
      isOpen={showVerification}
      withCloseBtn={false}
      modalHeight="auto"
      modalWidth="400px"
    >
      <VerifyAgentComponent setShowVerification={setShowVerification} />
    </Modal>
  );
};

export default AgentVerificationModal;
