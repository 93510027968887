import styled from 'styled-components';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import { useTranslation } from 'next-i18next';

interface MultiSelectpropsTypes {
  options: InputOption[];
  selectedValues: Array<number | string>;
  shapeClass: string;
  onChange: any;
}
interface inputPropType {
  inputOption: InputOption;
}
const MultiSelectPresentation = ({
  options,
  selectedValues,
  shapeClass,
  onChange
}: MultiSelectpropsTypes) => {
  const { t } = useTranslation('search');
  const Input = ({ inputOption }: inputPropType) => {
    const addClasses = (): string => {
      if (selectedValues) {
        const isSelected: boolean = selectedValues.includes(inputOption.value);

        if (isSelected) {
          return `${shapeClass} shape selected`;
        } else {
          return `${shapeClass} shape`;
        }
      } else {
        return `${shapeClass} shape`;
      }
    };
    return (
      <div
        className={addClasses()}
        onClick={() => handleInputStatues(inputOption.value)}
      >
        {t(inputOption.options.displayedValue)}
      </div>
    );
  };
  const handleInputStatues = (inputId: string | number) => {
    const isSelected = selectedValues.includes(inputId);
    if (isSelected) {
      const newSelected = selectedValues.filter(
        (curr: string | number) => curr != inputId
      );

      onChange(newSelected);
    } else {
      const newSelected = [...selectedValues, inputId];

      onChange(newSelected);
    }
  };

  return (
    <Container className="multi-select">
      {options
        .filter(option => option.value !== 'move_now_pay_later')
        .map(curr => (
          <Input inputOption={curr} key={curr.value} />
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  .circleShape {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background: ${props => props.theme.light.colors.white};
    color: ${props => props.theme.light.colors.darkGrey};
    border: 1px solid ${props => props.theme.light.colors.white};
    font-size: ${props => props.theme.font.sizes.xxs};
    font-family: materialMedium;
  }
  .shape {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.light.colors.white};
    color: ${props => props.theme.light.colors.darkGrey};
    border: 1px solid ${props => props.theme.light.colors.white};
    font-size: ${props => props.theme.font.sizes.l};
    padding: 5px 8px;
    &:hover {
      color: ${props => props.theme.light.colors.secondary};
      border: 1px solid ${props => props.theme.light.colors.secondary};
    }
  }
  .circleShape {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    font-size: ${props => props.theme.font.sizes.xxs};
  }

  .straightShape {
    border-radius: ${props => props.theme.borderRadius};
    font-size: ${props => props.theme.font.sizes.xxs};
  }
  .selected {
    background: ${props => props.theme.light.colors.secondary} !important;
    color: ${props => props.theme.light.colors.white} !important;
    border: 1px solid ${props => props.theme.light.colors.secondary} !important;
  }
`;

export default MultiSelectPresentation;
