const PlusIcon = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="8.86133"
        width="22"
        height="4.27778"
        rx="2.13889"
        fill="#12B76A"
      />
      <rect
        x="13.1387"
        width="22"
        height="4.27778"
        rx="2.13889"
        transform="rotate(90 13.1387 0)"
        fill="#12B76A"
      />
    </svg>
  );
};

export default PlusIcon;
