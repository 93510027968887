import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAllWatchList } from '../../../redux/slices/favoriteSlice';

const useMaintainFavoritesState = () => {
  const favorites = useSelector((state: any) => state.favorites);

  const dispatch = useDispatch();

  useEffect(() => {
    const consumeFavoritesState = () => {
      const capturedFavoritesState = localStorage?.getItem('favorites_state');

      dispatch(addAllWatchList(JSON.parse(capturedFavoritesState!)));

      localStorage.removeItem('favorites_state');
    };
    if (localStorage?.getItem('favorites_state')) {
      consumeFavoritesState();
    }
  }, []);

  useEffect(() => {
    const captureFavoritesState = () => {
      localStorage.setItem(
        'favorites_state',
        JSON.stringify({
          compounds: favorites.compoundFavorites,
          properties: favorites.propertyFavorites
        })
      );
    };
    window.addEventListener('pagehide', captureFavoritesState);

    return () => {
      window.removeEventListener('pagehide', captureFavoritesState);
    };
  }, [favorites]);
};

export default useMaintainFavoritesState;
