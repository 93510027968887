interface CheckedIconProps {
  styles?: object;
  fill?: string;
}

const CheckedIcon = ({ styles, fill }: CheckedIconProps) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.08896 7.28207C7.86625 5.60831 9.67805 3.90004 11.4899 2.20903C12.111 1.62235 12.7322 1.01842 13.3707 0.448995C14.0781 -0.172193 14.9927 -0.137683 15.6139 0.483506C16.2523 1.12195 16.2696 2.1055 15.5621 2.77845C12.7322 5.45301 9.90237 8.11032 7.05526 10.7676C6.33054 11.4406 5.46777 11.4233 4.76031 10.7331C3.32812 9.28368 1.9132 7.83424 0.515521 6.35029C-0.174688 5.60831 -0.157433 4.69378 0.481011 4.05534C1.13671 3.39964 2.08575 3.43415 2.84498 4.15887C3.89755 5.17693 4.95012 6.17773 6.08896 7.28207Z"
        fill={fill ?? '#1E4164'}
      />
    </svg>
  );
};

export default CheckedIcon;
