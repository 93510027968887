import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import PlusIcon from '../../../../../public/assets/icons/common/PlusIcon';
import { Dispatch, SetStateAction } from 'react';
import SearchMenu from './SearchMenu';
import CloseIcon from '../../../../../new-components/svg-icons/CloseIcon';

interface ContainerProps {
  filterOptions: any;
  selectedOptions?: any;
  handleSelect: any;
  filterName: string;
  filterType: string;
  areasDevelopersMenu: boolean;
  setAreasDevelopersMenu: Dispatch<SetStateAction<boolean>>;
}

const DrawerFilterPresentation = ({
  filterOptions,
  selectedOptions,
  handleSelect,
  filterName,
  filterType,
  areasDevelopersMenu,
  setAreasDevelopersMenu
}: ContainerProps) => {
  const { t } = useTranslation(['search', 'common']);

  return (
    <Container>
      {selectedOptions.length > 0 ? (
        <>
          {selectedOptions?.slice(0, 4).map((option: any, index: number) => {
            return (
              <>
                {index === 3 ? (
                  <span className={'option-button'}>
                    {`+ ${selectedOptions.length - 3} ${t('more')}`}
                  </span>
                ) : (
                  <button
                    key={option.value}
                    className={'option-button'}
                    onClick={() => handleSelect(option.value)}
                  >
                    {t(option.options.displayedValue)}
                    <CloseIcon styles={{ width: '12px', height: '12px' }} />
                  </button>
                )}
              </>
            );
          })}
          <button
            className="option-button add-more-btn"
            onClick={() => setAreasDevelopersMenu(true)}
          >
            <PlusIcon />
            <span>{t(`add_${filterName}`, { ns: 'common' })}</span>
          </button>
        </>
      ) : (
        <button
          className="add-btn"
          onClick={() => setAreasDevelopersMenu(true)}
        >
          <PlusIcon />
          <span>{t(`add_${filterName}`, { ns: 'common' })}</span>
        </button>
      )}
      {areasDevelopersMenu && (
        <SearchMenu
          filterName={filterName}
          options={filterOptions}
          type={filterType}
          setAreasDevelopersMenu={setAreasDevelopersMenu}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  .add-btn {
    display: flex;
    width: 100%;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 360px;
    border: 1px solid #c6d0de;
    background: #fff;

    span {
      color: #1a2028;
      text-align: center;
      font-size: 14px;
      font-family: materialMedium;
    }
  }

  .option-button {
    font-family: materialRegular;
    font-size: 14px;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 360px;
    background-color: #fff;
    color: #5b6573;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &.add-more-btn {
      background: #e8f4ff;
    }
  }
  .selected {
    background-color: #015c9a;
    color: #fff;
  }
`;

export default DrawerFilterPresentation;
