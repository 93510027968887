import styled from 'styled-components';
import SearchSelections from './LocationSearchSelections';
import SearchLocationResults from './LocationSearchResults';
import { SearchSelection } from '../../../hooks/LocationFilter';

interface DropdownProps {
  selectedSearches: SearchSelection[];
  handleRemove: (selection: SearchSelection) => void;
  searchResults: SearchSelection[];
  handleSelect: (selection: SearchSelection) => void;
  dropdownRef?: any;
  clearAll: () => void;
  filterType: 'areas' | 'compounds';
}

const LocationSearchDropdown = ({
  selectedSearches,
  handleRemove,
  searchResults,
  handleSelect,
  dropdownRef,
  clearAll,
  filterType
}: DropdownProps) => {
  const selectedIds = selectedSearches?.map(result => result.id);
  return (
    <Dropdown
      className="search-dropdown"
      ref={dropdownRef}
      data-test="search-input-dropdown"
    >
      {selectedSearches?.length > 0 && (
        <SearchSelections
          clearClick={clearAll}
          selectedSearches={selectedSearches}
          handleRemove={handleRemove}
        />
      )}
      <SearchLocationResults
        searchResults={searchResults.filter(
          result => `${result.category}s` === filterType
        )}
        handleSelect={handleSelect}
        selectedIds={selectedIds}
        handleRemove={handleRemove}
      />
    </Dropdown>
  );
};

const Dropdown = styled.div`
  background-color: ${props => props.theme.light.backgroundColor};
  width: 100%;
  overflow-y: auto;
  border-radius: 10px;
  position: absolute;
  top: 110px;
  padding: 16px;
  max-height: 180px;
  box-shadow: 0px 4px 10px 0px #00000026;
  z-index: 99;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default LocationSearchDropdown;
