import { ParsedUrlQuery } from 'querystring';
import { WebviewElementsMap } from './constants/webview';

export const getElementsToHide = (webview: string): string[] => {
  return WebviewElementsMap.get(webview) ?? [];
};

export const removeElements = (elements: string[]) => {
  const removeElement = (idSelector: string) => {
    document.getElementById(idSelector)?.remove();
  };

  if (elements.length > 0) {
    elements.forEach(element => {
      removeElement(element);
    });
  }
};

export const getWebviewType = (query: ParsedUrlQuery): string | null => {
  if (query.partners) {
    return 'partners';
  } else if (query['hide-all']) {
    return 'app';
  }

  return null;
};
