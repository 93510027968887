interface MenuBurgerIconProps {
  width?: number;
  height?: number;
}
const MenuBurgerIcon = ({ width, height }: MenuBurgerIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '28'}
      height={height ?? '21'}
      viewBox="0 0 28 21"
      fill="none"
    >
      <rect y="0.75" width="28" height="2.5" rx="1.25" fill="#1E4164" />
      <rect x="12" y="17.75" width="16" height="2.5" rx="1.25" fill="#1E4164" />
      <rect y="9.25" width="28" height="2.5" rx="1.25" fill="#1E4164" />
    </svg>
  );
};

export default MenuBurgerIcon;
