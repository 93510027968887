interface PropTypes {
  stroke?: string;
  styles?: object;
}
const ThinArrowIcon = ({ stroke, styles }: PropTypes) => {
  return (
    <svg
      width="14"
      height="25"
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M11.6623 22.894L2.4707 12.4529L11.6623 2.01181"
        stroke={stroke || '#212427'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThinArrowIcon;
