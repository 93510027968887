import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import Cookies from 'universal-cookie';
import {
  addCompoundToFavorites,
  addPropertyToFavorites,
  removeCompoundFromFavorites,
  removePropertyFromFavorites
} from '../redux/slices/favoriteSlice';
import { getFavorites, updateWatchList } from '../new-lib/apis';
import { EntityTypes } from '../new-lib/constants/common';
import { trackEvent } from '../new-lib/remarketing';

const cookies = new Cookies();

interface WatchListPayload {
  client_id: string;
  entity_id: number;
}

export const updateFavoritesInWatchList = (
  isFavorite: boolean,
  entityType: EntityTypes,
  payload: WatchListPayload
) => {
  const body = {
    client_id: payload.client_id.toString(),
    ...(entityType == 'property' && { property_id: payload.entity_id }),
    ...(entityType == 'compound' && { compound_id: payload.entity_id })
  };

  addOrRemoveFromFavorites(body, isFavorite ? 'unfollow' : 'follow');
};

export const updateFavoritesInStore = (
  isFavorite: boolean,
  entityType: EntityTypes,
  dispatch: Dispatch<AnyAction>,
  payload: number
) => {
  if (isFavorite) {
    entityType == 'property'
      ? dispatch(removePropertyFromFavorites(payload))
      : dispatch(removeCompoundFromFavorites(payload));
  } else {
    trackEvent.initiateCheckout({
      listingIds: [(payload as any).id],
      listingPrice: (payload as any).min_price
    });
    entityType == 'property'
      ? dispatch(addPropertyToFavorites(payload))
      : dispatch(addCompoundToFavorites(payload));
  }
};

export const checkIsFavorite = (entities: any[], entityID: number) => {
  return entities.find(p => p.id == entityID) !== undefined;
};

export const handleClickTheFavorite = (
  isFavorite: boolean,
  entityType: EntityTypes,
  dispatch: Dispatch<AnyAction>,
  payload: WatchListPayload
) => {
  updateFavoritesInWatchList(isFavorite, entityType, payload);
  updateFavoritesInStore(isFavorite, entityType, dispatch, payload.entity_id);
};

export const getUserFavorites = async (
  language?: string,
  client_id?: string
) => {
  const clientID = client_id ?? cookies.get('clientID');

  const favorites: any[] = await getFavorites(clientID, language);

  const favProperties = favorites
    .filter(favorite => favorite.type === 'Property')
    .map(favorite => favorite.data.id);

  const favCompounds = favorites
    .filter(favorite => favorite.type === 'Compound')
    .map(favorite => favorite.data.id);

  return {
    properties: favProperties,
    compounds: favCompounds
  };
};

export const getFavoritesData = async (
  language?: string,
  client_id?: string
) => {
  const clientID = client_id ?? cookies.get('clientID');

  const favorites: any[] = await getFavorites(clientID, language);

  const compounds = favorites
    .filter(favorite => favorite.type === 'Compound')
    .map(favorite => favorite.data);
  const properties = favorites
    .filter(favorite => favorite.type === 'Property')
    .map(favorite => favorite.data);

  return {
    compounds,
    properties
  };
};

export const addOrRemoveFromFavorites = async (body: {}, type: string) => {
  const addOrRemovedEntityStatues = await updateWatchList(
    { ...body, category: 'favorite' },
    type
  );
  return addOrRemovedEntityStatues;
};
