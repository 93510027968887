type PropsType = {
  stroke: string;
  styles?: any;
};

const ArrowIcon = ({ stroke, styles }: PropsType) => {
  return (
    <svg
      style={styles}
      viewBox="0 0 19 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.92148 3.82349L16 17.5618L3.92148 31.3001"
        stroke={stroke}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowIcon;
