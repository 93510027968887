import { ReactNode } from 'react';
import styled from 'styled-components';

interface IconHolderProps {
  children: ReactNode;
}

const IconHolder = ({ children }: IconHolderProps) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  object-fit: scale-down;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default IconHolder;
