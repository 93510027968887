import { MouseEventHandler } from 'react';
import styled from 'styled-components';

interface buttonProps {
  text: string;
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  hoverColor?: string;
  btnClass?: string;
  containerClass?: string;
  fontSize: string;
  padding?: string;
  border: string;
  onClick: MouseEventHandler;
  disabled?: boolean;
}

interface containerProps {
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  hoverColor?: string;
  fontSize: string;
  padding?: string;
  border: string;
}

const Button = ({
  text,
  backgroundColor,
  backgroundHover,
  color,
  hoverColor,
  fontSize,
  padding,
  border,
  btnClass,
  containerClass,
  disabled,
  onClick
}: buttonProps) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      backgroundHover={backgroundHover}
      color={color}
      hoverColor={hoverColor}
      fontSize={fontSize}
      padding={padding}
      border={border}
      className={containerClass}
    >
      <button
        className={btnClass}
        onClick={onClick}
        disabled={disabled}
        data-test="search-btn"
      >
        {text}
      </button>
    </Container>
  );
};

const Container = styled.div<containerProps>`
  button {
    font-family: materialRegular;
    border-radius: ${props => props.theme.borderRadius};
    height: 35px;
    border: ${props => props.border};
    background-color: ${props => props.backgroundColor};
    font-size: ${props => props.fontSize};
    padding: ${props => props.padding};
    color: ${props => props.color};
    cursor: pointer;
    transition: all 0.3s ease-out;
    text-transform: capitalize;

    @media screen and (min-width: ${props =>
        props.theme.breakPoints.desktop.min}) {
      :hover {
        background-color: ${props => props.backgroundHover};
        color: ${props => props.hoverColor};
      }
    }
  }
`;
export default Button;
