import styled from 'styled-components';
import CompareIcon from '../../../../new-components/svg-icons/CompareIcon';
import { useRouter } from 'next/router';
import { getFirstPathSegment, logEvent } from '../../../../new-lib/analytics';
import { useSelector } from 'react-redux';

const CompareWidget = () => {
  const router = useRouter();
  const { asPath } = useRouter();

  const { compare_count } = useSelector((state: any) => state.compare);

  const handleRoute = () => {
    logEvent.click('cmpr_view_all', getFirstPathSegment(asPath), 'compare');

    router.push('/compare');
  };

  return compare_count > 0 ? (
    <Container
      onClick={handleRoute}
      // style={router.asPath.includes('/nawy-now') ? { marginBottom: '70px' } : {}}
    >
      <CompareIcon fill="#fff" />
      <span className="filters-indicator">{compare_count}</span>
    </Container>
  ) : null;
};

const Container = styled.button`
  position: relative;
  align-self: flex-end;
  align-items: flex-end;
  border: none;
  outline: none;
  width: 55px;
  height: 55px;
  background-color: #015c9a;
  border-radius: 50%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;

  svg {
    width: 32px;
    height: 28px;
  }

  span {
    position: absolute;
    top: 2px;
    inset-inline-end: 4px;
    font-size: 10px;
    color: ${props => props.theme.light.colors.white};
    background-color: ${props => props.theme.light.colors.primaryOrange};
    font-family: materialMedium;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
`;
export default CompareWidget;
