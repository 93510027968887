import { print } from './helpers';

export const AREA = 'area';
export const DEVELOPER = 'developer';
export const COMPOUND = 'compound';
export const PROPERTY_TYPE = 'property_type_id';
export const PRICE = 'price';
export const BEDROOMS = 'bedrooms';
export const UNIT_AREA = 'unit_area';
export const INSTALLMENT_YEARS = 'installment_years';
export const MONTHLY_INSTALLMENT = 'monthly_installment';
export const DOWN_PAYMENT = 'down_payment';
export const DELIVERY_DATE = 'delivery_date';

export const QUERY_TYPES = {
  id: 'id',
  range: 'range',
  value: 'value',
  array: 'array',
  resale: 'resale',
  date: 'date'
};

function toApiQuery(value) {
  if (this.type == QUERY_TYPES.id) {
    return { [this.apiName + '_id']: value };
  }
  if (this.type == QUERY_TYPES.range) {
    let valueArray = value.split(',');
    if (valueArray.length < 2) {
      throw 'Range should have min and max';
    }
    return {
      ['min_' + this.apiName]: valueArray[0],
      ['max_' + this.apiName]: valueArray[1]
    };
  }
  if (this.type == QUERY_TYPES.value) {
    return { [this.apiName]: value };
  }
  if (this.type == QUERY_TYPES.array) {
    return { [this.apiName]: value.split(',') };
  }
  if (this.type == QUERY_TYPES.resale) {
    if (value.includes('resale') && value.includes('developer_sale')) {
      return;
    }

    return { [this.apiName]: value };
  }
  if (this.type == QUERY_TYPES.date) {
    const query = value.split(',');
    const date = query[0].split('-');
    return { [this.apiName]: { date: date, exclude_movenow: query[1] } };
  }
}

export const QUERY_PARAMS = {
  area: { apiName: 'area', type: QUERY_TYPES.id, toApiQuery },
  developer: { apiName: 'developer', type: QUERY_TYPES.id, toApiQuery },
  compound: { apiName: 'compound', type: QUERY_TYPES.id, toApiQuery },
  property_type: {
    apiName: 'property_type_id',
    type: QUERY_TYPES.array,
    toApiQuery
  },
  property: { apiName: 'property', type: QUERY_TYPES.id, toApiQuery },
  price: { apiName: 'price', type: QUERY_TYPES.range, toApiQuery },
  monthly_installment: {
    apiName: 'installments',
    type: QUERY_TYPES.range,
    toApiQuery
  },
  down_payment: {
    apiName: 'down_payment',
    type: QUERY_TYPES.range,
    toApiQuery
  },
  installment_years: {
    apiName: 'installment_years',
    type: QUERY_TYPES.range,
    toApiQuery
  },
  bedrooms: {
    apiName: 'number_of_bedrooms',
    type: QUERY_TYPES.range,
    toApiQuery
  },
  bathrooms: {
    apiName: 'number_of_bathrooms',
    type: QUERY_TYPES.range,
    toApiQuery
  },
  unit_area: { apiName: 'unit_area', type: QUERY_TYPES.range, toApiQuery },
  // delivery_date       : { apiName: "delivery_date"       , type: QUERY_TYPES.value , toApiQuery},
  resale: { apiName: 'resale', type: QUERY_TYPES.resale, toApiQuery },
  finishing: { apiName: 'finishing', type: QUERY_TYPES.array, toApiQuery },

  order_by: { apiName: 'order_by', type: QUERY_TYPES.value, toApiQuery },
  order_direction: {
    apiName: 'order_direction',
    type: QUERY_TYPES.value,
    toApiQuery
  },
  delivery_date: {
    apiName: 'min_ready_by',
    type: QUERY_TYPES.date,
    toApiQuery
  }
};
