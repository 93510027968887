import { GrowthBook } from '@growthbook/growthbook';
import { NextRequest, NextResponse } from 'next/server';

interface ExperimentDetails {
  key: string;
  variations: any[];
  weights: number[];
}

export interface ExperimentPayload {
  id: string;
  variant: unknown;
}

const experimentDetails = {
  key: '_ab',
  variations: ['cta_contact', 'cta_buy', 'cta_reserve', 'cta_empty'],
  weights: [0.25, 0.25, 0.25, 0.25]
};

export const getExperimentPayload = (
  request: NextRequest
): ExperimentPayload => {
  const isExperimentSetup = (request: NextRequest) => {
    return (
      request.cookies.has('_gb') && request.cookies.has(experimentDetails.key)
    );
  };

  if (isExperimentSetup(request)) {
    return {
      id: request.cookies.get('_gb')?.value as string,
      variant: request.cookies.get(experimentDetails.key)?.value as string
    };
  }

  return initializeExperiment(experimentDetails);
};

export const generateRandomId = () => {
  let randomID = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    randomID += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return randomID;
};

export function getExperimentContext(id: string) {
  return new GrowthBook({
    user: { id }
  });
}

export const initializeExperiment = (
  args: ExperimentDetails
): ExperimentPayload => {
  const id = generateRandomId();
  const ctx = getExperimentContext(id);

  const { value: variant } = ctx.run({
    key: args.key,
    variations: args.variations as any,
    weights: args.weights
  });

  return { id, variant };
};

export const setExperiment = (
  response: NextResponse,
  experimentPayload: ExperimentPayload
) => {
  response.cookies.set('_gb', experimentPayload.id);

  response.cookies.set(
    experimentDetails.key,
    experimentPayload.variant as string
  );
};
