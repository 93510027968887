import { useRouter } from 'next/router';
import { useState } from 'react';
import { fetchAgentInfo } from '../new-lib/apis';
import { logEvent } from '../new-lib/analytics';
import { getPageLocation } from '../helpers/common';

const useAgentVerification = () => {
  const router = useRouter();
  const [phoneNumber, setPhoneNumber] = useState<any>(null);
  const [agentInfo, setAgentInfo] = useState({
    success: false,
    name: '',
    isInvalidNumber: false,
    errorMessage: ''
  });
  const [checkAgent, setCheckedAgent] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
    if (value.length < 11) {
      setCheckedAgent(false);
    }
  };
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    let value = event.clipboardData.getData('text').replace(/\s+/g, ''); // Remove spaces
    if (value.startsWith('+2')) {
      value = value.slice(2);
    }
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
      if (value.length < 11) {
        setCheckedAgent(false);
      }
    }
  };

  const handleVerifyAgent = async () => {
    try {
      const response = await fetchAgentInfo(phoneNumber);

      if (response.data.isNawyAgent) {
        setAgentInfo({
          success: true,
          name: response.data.name,
          isInvalidNumber: false,
          errorMessage: ''
        });
      } else {
        setAgentInfo({
          success: false,
          name: '',
          isInvalidNumber: false,
          errorMessage: ''
        });
      }
    } catch (error: any) {
      setAgentInfo({
        success: false,
        name: '',
        isInvalidNumber: error.message === 'Network Error' ? false : true,
        errorMessage:
          error?.message === 'Network Error' ? 'trial_limit_reach' : ''
      });
    }
    logEvent.click(
      'validate_agent',
      getPageLocation(router.pathname),
      'verify-agent',
      {
        valid_agent: agentInfo.success
      }
    );
    setCheckedAgent(true);
  };

  return {
    phoneNumber,
    agentInfo,
    checkAgent,
    handleInputChange,
    handleVerifyAgent,
    handlePaste
  };
};

export default useAgentVerification;
