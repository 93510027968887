import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import CompareIcon from '../../../new-components/svg-icons/CompareIcon';
import Button from '../../../new-components/ui/Button';
import { resetCompareState } from '../../../redux/slices/compareSlice';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getFirstPathSegment, logEvent } from '../../../new-lib/analytics';
import { useDispatch, useSelector } from 'react-redux';
const CompareToolbar = () => {
  const { t } = useTranslation('common');

  const { asPath } = useRouter();

  const { compare_count, compounds_ids, properties_ids } = useSelector(
    (state: any) => state.compare
  );

  const dispatch = useDispatch();
  const getCompareInfo = () => {
    let comparedEntities = [];

    properties_ids.length > 0 &&
      comparedEntities.push(
        `${properties_ids.length} ${t('properties', { ns: 'common' })}`
      );
    compounds_ids.length > 0 &&
      comparedEntities.push(
        `${compounds_ids.length} ${t('compounds', { ns: 'common' })}`
      );

    return comparedEntities.join(',');
  };

  const cancelCompare = () => {
    logEvent.click('cmpr_stop', getFirstPathSegment(asPath), 'compare');
    dispatch(resetCompareState());
  };

  const handleStartCompare = () => {
    logEvent.click('cmpr_view_all', getFirstPathSegment(asPath), 'compare');
  };

  return compare_count > 0 ? (
    <Container>
      <div className="container-fluid">
        <div className="compared-items">{getCompareInfo()}</div>
        <div className="action-btns">
          <Link
            href="/compare"
            className="start-compare"
            onClick={handleStartCompare}
          >
            {/* <CompareIcon fill="#fff" /> */}
            {t('start_compare')}
            <span className="filters-indicator">{compare_count}</span>
          </Link>
          <Button
            backgroundColor="#E4EBF2"
            border="none"
            color="#0C314E"
            fontSize="14px"
            onClick={cancelCompare}
            padding="8px 0"
            text={t('cancel')}
            btnClass="cancel-compare"
          />
        </div>
      </div>
    </Container>
  ) : null;
};

const Container = styled.div`
  background-color: #fff;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 606px;
  height: 72px;
  pointer-events: all;
  border-radius: 12px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px;
  margin: 0 auto;

  .container-fluid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .compared-items {
      font-family: materialBold;
      color: #1a2028;
      font-size: 17px;
    }
    .action-btns {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .start-compare {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        gap: 4px;
        border: none;
        outline: none;
        background-color: #015c9a;
        color: ${props => props.theme.light.colors.white};
        font-size: ${props => props.theme.font.sizes.s};
        font-family: materialMedium;
        border-radius: ${props => props.theme.borderRadius};
        height: 42px;
        padding: 8px 16px;

        svg {
          width: 28px;
          height: 24px;
        }

        span {
          font-family: materialBold;
          color: ${props => props.theme.light.colors.white};
          background-color: ${props => props.theme.light.colors.primaryOrange};
          font-family: materialMedium;
          font-size: 12px;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
      }
      .cancel-compare {
        padding: 8px;
        font-family: materialMedium;
        height: 42px;
        width: 90px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #c6d0de;
        background: #fbfcfe;
      }
    }
  }
`;
export default CompareToolbar;
