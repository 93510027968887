const FilterIcon = () => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="settings-2">
        <path
          id="Vector"
          d="M22.5 8.375H12.375M15.75 19.625H5.625M15.75 19.625C15.75 21.489 17.261 23 19.125 23C20.989 23 22.5 21.489 22.5 19.625C22.5 17.761 20.989 16.25 19.125 16.25C17.261 16.25 15.75 17.761 15.75 19.625ZM11.25 8.375C11.25 10.239 9.73896 11.75 7.875 11.75C6.01104 11.75 4.5 10.239 4.5 8.375C4.5 6.51104 6.01104 5 7.875 5C9.73896 5 11.25 6.51104 11.25 8.375Z"
          stroke="#788496"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default FilterIcon;
