type PropsType = {
  fill?: string;
  width?: string;
  height?: string;
};

const CompareIcon = ({ width, height, fill }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '22'}
      height={height ?? '18'}
      viewBox="0 0 22 18"
      fill="none"
    >
      <path
        d="M11.1638 4.00635C11.908 4.67805 12.6619 5.34115 13.4061 6.01286C15.2716 7.68351 17.137 9.34556 18.9831 11.0248C19.1474 11.1798 19.2731 11.4382 19.2731 11.6535C19.2924 13.4016 19.2924 15.1498 19.2827 16.9066C19.2827 17.7161 18.9638 18.0002 18.0649 18.0002C13.4448 18.0002 8.82472 18.0002 4.20464 18.0002C3.29609 18.0002 2.98679 17.7247 2.98679 16.9152C2.98679 15.1498 2.97713 13.3844 2.99646 11.619C2.99646 11.4554 3.06411 11.2401 3.18977 11.1282C5.78977 8.77719 8.40911 6.44344 11.0188 4.10969C11.0574 4.06663 11.0961 4.04941 11.1638 4.00635ZM17.9489 16.8291C17.9682 16.7602 17.9876 16.7257 17.9876 16.6827C17.9876 15.1067 17.9972 13.5308 17.9876 11.9549C17.9876 11.8085 17.8619 11.6363 17.7363 11.5243C15.6485 9.63835 13.5415 7.76102 11.4441 5.88368C11.3667 5.81479 11.2604 5.76312 11.1444 5.677C11.1444 9.43167 11.1444 13.1174 11.1444 16.8377C13.4255 16.8291 15.6679 16.8291 17.9489 16.8291Z"
        fill={fill ?? '#697586'}
      />
      <path
        d="M11.0815 2.02401C9.47702 3.43632 7.94988 4.77112 6.42273 6.11453C5.15656 7.23405 3.90005 8.37078 2.64354 9.49891C2.31492 9.7917 1.91863 9.89504 1.50302 9.7142C1.1164 9.55057 0.913427 9.24917 1.00042 8.87026C1.04874 8.67219 1.17439 8.46551 1.33871 8.31911C4.306 5.64951 7.28296 2.9799 10.2696 0.31891C10.7625 -0.120283 11.2651 -0.10306 11.7774 0.353356C14.7254 2.98851 17.6637 5.62367 20.602 8.25883C21.1142 8.71525 21.1529 9.20611 20.7276 9.57641C20.312 9.9381 19.7901 9.89504 19.2875 9.43862C16.6584 7.08765 14.0391 4.73667 11.4198 2.3857C11.2845 2.27375 11.1878 2.14457 11.0815 2.02401Z"
        fill={fill ?? '#697586'}
      />
    </svg>
  );
};
export default CompareIcon;
