import Image from 'next/legacy/image';
import styled from 'styled-components';
import { SliderOption } from './SliderContainer';
import { getImagePath } from '../../../../../helpers/common';
import Slider from '../../../../../new-components/Slider';

type SliderPresentationProps = {
  sliderOptions: SliderOption[];
  optionHandler: (option: SliderOption) => void;
  checkOptionSelected: (id: number) => boolean;
};

const SliderPresentation = ({
  sliderOptions,
  optionHandler,
  checkOptionSelected
}: SliderPresentationProps) => {
  const getSliderOptions = () => {
    return sliderOptions.map((option, key) => {
      return (
        <SliderItem
          key={key}
          onClick={() => optionHandler(option)}
          className={`slider-item ${
            checkOptionSelected(option.id) && `selected-filter`
          }`}
        >
          <Image
            src={getImagePath(option.icon)}
            alt={`${option.name} Icon`}
            width={27.5}
            height={25}
            className="option-icon"
          />
          <span className="text-2">{option.name}</span>
          {option.count != undefined && (
            <span className="text-1">{option.count}</span>
          )}
        </SliderItem>
      );
    });
  };

  const sliderProps = {
    slidesPerView: 'auto' as 'auto',
    slidesPerGroup: 1,
    spaceBetweenSlides: 10,
    children: getSliderOptions()
  };

  return <Slider {...sliderProps} />;
};

const SliderItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  .text-1,
  .text-2 {
    white-space: nowrap;
    color: #b6b6b6;
    font-family: materialMedium !important;
  }
  &.selected-filter {
    .text-1,
    .text-2 {
      color: ${props => props.theme.light.colors.primaryBlue};
    }
    .option-icon {
      filter: brightness(0) saturate(100%) invert(19%) sepia(59%) saturate(680%)
        hue-rotate(168deg) brightness(93%) contrast(88%);
    }
  }
  .option-icon {
    margin-bottom: 2px !important;
    filter: brightness(0) saturate(100%) invert(91%) sepia(0%) saturate(2569%)
      hue-rotate(344deg) brightness(94%) contrast(61%);

    .text-2 {
      font-family: materialMedium;
      text-transform: capitalize;
    }

    object-fit: unset !important;
    min-height: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    max-width: unset !important;
    margin: unset !important;
    width: 100% !important;
    height: 100% !important;
  }
  @media (min-width: ${props => props.theme.breakPoints.desktop.min}) {
    &:hover {
      .option-icon {
        filter: brightness(0) saturate(100%) invert(19%) sepia(59%)
          saturate(680%) hue-rotate(168deg) brightness(93%) contrast(88%);
      }
      .text-1,
      .text-2 {
        color: ${props => props.theme.light.colors.primaryBlue};
      }
    }
  }
`;
export default SliderPresentation;
