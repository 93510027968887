import styled from 'styled-components';

interface ContainerProps {
  filterOptions: any;
  selectedValues: any;
  handleSelect: any;
  maxSelectNumber?: number;
}

const NumberSelectFilterPresentation = ({
  filterOptions,
  selectedValues,
  handleSelect,
  maxSelectNumber
}: ContainerProps) => {
  const getNumbersRange = () => {
    let numbers = [];
    let maxChips = maxSelectNumber ?? 5;
    if (filterOptions.length < maxChips) {
      maxChips = filterOptions.length;
      return filterOptions;
    } else {
      numbers = filterOptions.slice(0, maxChips - 1);
      numbers.push({
        value: filterOptions[maxChips - 1].value,
        options: { displayedValue: `${filterOptions[maxChips - 1].value}+` }
      });
      return numbers;
    }
  };
  return (
    <Container>
      {getNumbersRange().map((option: any) => {
        return (
          <button
            key={option.value}
            className={
              selectedValues.includes(option.value)
                ? 'selected number-button'
                : 'number-button'
            }
            onClick={() => handleSelect(option)}
          >
            {option.options.displayedValue}
          </button>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .number-button {
    padding: 4px;
    width: 30px;
    height: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    background-color: #fff;
    color: #5b6573;
  }
  .selected {
    background-color: #015c9a;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    gap: 4px;
    .number-button {
      font-size: 14px;
      padding: 4px;
      width: 50px;
      height: 50px;
    }
  }
`;

export default NumberSelectFilterPresentation;
