import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Select from 'react-select';
import { ReactNode, useState } from 'react';
import { ListItemType } from './ContactFormContainer';
import PhoneInput, {
  Country,
  isValidPhoneNumber
} from 'react-phone-number-input/max';
import CountrySelect from './components/CountrySelect';
import Dropdown from './components/Dropdown';

interface FormProps {
  formik: any;
  customerNumber: any;
  onPhoneNumberChange: (valid: any, value: any) => void;
  compounds: ListItemType[];
  areas: ListItemType[];
  propertyTypes: ListItemType[];
  handleCompoundSearchInput: (searchInput: string) => void;
  handleListItemSelection: (
    listName: string,
    selectedItem: ListItemType
  ) => void;
  handleBuyerOrSeller: (seller: boolean) => void;
  renderedInputs?: {
    name?: boolean;
    location?: boolean;
    mobileNumber?: boolean;
    message?: boolean;
    email?: boolean;
    buyerOrSeller?: boolean;
  };
  withLabels?: boolean;
  layout?: {
    header?: ReactNode;
    footer?: ReactNode;
  };
  handleResetForm: (preservedValues: {}) => void;
}

const ContactFormPresentation = ({
  formik,
  customerNumber,
  onPhoneNumberChange,
  handleListItemSelection,
  handleCompoundSearchInput,
  handleBuyerOrSeller,
  compounds,
  areas,
  propertyTypes,
  renderedInputs,
  withLabels,
  layout
}: FormProps) => {
  const { t } = useTranslation('contact');
  const { locale } = useRouter();

  const [country, setCountry] = useState<string>('EG');

  const [phoneInputDirection, setPhoneInputDirection] = useState(
    locale === 'en' ? 'ltr' : 'rtl'
  );

  const resetInput = () => {
    onPhoneNumberChange(true, '');
    formik.handleChange({
      target: { name: 'contact_number', value: '' }
    });
  };

  return (
    <Container
      id="contact-form"
      className="contact-form"
      itemScope
      itemType="https://schema.org/ContactPage"
    >
      {layout?.header && layout.header}

      <form onSubmit={formik.handleSubmit} className="form">
        {renderedInputs?.buyerOrSeller && (
          <div className="buyer-seller-tabs">
            <div
              className={!formik.values.seller ? 'active-tab' : ''}
              onClick={() => {
                handleBuyerOrSeller(false);
              }}
            >
              {t('buy')}
            </div>

            <div
              className={formik.values.seller ? 'active-tab' : ''}
              onClick={() => {
                handleBuyerOrSeller(true);
              }}
            >
              {t('sell')}
            </div>
          </div>
        )}
        {renderedInputs?.name && (
          <div className="form-input">
            {withLabels && (
              <label>
                {t('name')} <span>*</span>
              </label>
            )}
            <input
              type="text"
              placeholder={t('your_name')}
              id="contact_name"
              className="input-field"
              name="contact_name"
              value={formik.values.contact_name}
              onChange={formik.handleChange}
            />
            <div className="form-errors">
              {formik.errors.contact_name && formik.touched.contact_name
                ? formik.errors.contact_name
                : null}
            </div>
          </div>
        )}
        {renderedInputs?.email && (
          <div className="form-input">
            {withLabels && (
              <label>
                {t('email')} <span>*</span>
              </label>
            )}
            <input
              type="email"
              placeholder={t('your_email')}
              id="contact_email"
              className="input-field"
              name="contact_email"
              value={formik.values.contact_email}
              onChange={formik.handleChange}
            />
            <div className="form-errors">
              {formik.errors.contact_email && formik.touched.contact_email
                ? formik.errors.contact_email
                : null}
            </div>
          </div>
        )}
        {renderedInputs?.location && (
          <div className="form-input" data-test="location-input">
            {withLabels && (
              <label id="aria-label" htmlFor="aria-phone-input">
                {t('location')} <span>*</span>
              </label>
            )}
            <Dropdown locale={locale}>
              <Select
                options={compounds}
                name="compound"
                label="compound"
                placeholder={t('prefered_location')}
                styles={{ supressHydrationWarning: true }}
                instanceId="compound"
                onInputChange={(input: any) => handleCompoundSearchInput(input)}
                onChange={(selected: ListItemType) =>
                  handleListItemSelection('compound', selected)
                }
                aria-label="compound-select"
                aria-activedescendant=""
                className="select"
                classNamePrefix="select"
                value={
                  formik.values.compound?.value ? formik.values.compound : null
                }
                noOptionsMessage={() => {
                  return t('no_options');
                }}
              />
            </Dropdown>

            <div className="form-errors">
              {formik.errors.compound && formik.touched.compound
                ? formik.errors.compound
                : null}
            </div>
          </div>
        )}
        {renderedInputs?.mobileNumber && (
          <div className="form-input">
            {withLabels && (
              <label dir="auto">
                {t('mobile')} <span>*</span>
              </label>
            )}
            <div
              style={{
                borderRadius: '8px',
                justifyContent: locale == 'en' ? 'flex-start' : 'flex-end',
                display: ' flex',
                gap: '8px',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
                border: '1px solid #e6e6e6'
              }}
            >
              <PhoneInput
                value={customerNumber.value}
                className="phone-input"
                numberInputProps={{
                  id: 'contact_number',
                  name: 'contact_number',
                  type: 'text',
                  style: {
                    direction: phoneInputDirection
                  }
                }}
                countryCallingCodeEditable={true}
                defaultCountry={country as Country}
                countrySelectComponent={() => (
                  <CountrySelect
                    country={country}
                    setCountry={setCountry}
                    touched={formik.touched.contact_number}
                    error={formik.errors.contact_number}
                    resetInput={resetInput}
                  />
                )}
                placeholder={t('phone_number')}
                focusInputOnCountrySelection
                onChange={value => {
                  if (value) {
                    if (phoneInputDirection === 'rtl') {
                      setPhoneInputDirection('ltr');
                    }
                    onPhoneNumberChange(
                      isValidPhoneNumber((value as string) ?? ''),
                      value
                    );
                    formik.handleChange({
                      target: { name: 'contact_number', value: value }
                    });
                  } else {
                    if (locale === 'ar' && phoneInputDirection === 'ltr') {
                      setPhoneInputDirection('rtl');
                    }
                  }
                }}
              />
            </div>
            <div className="form-errors">
              {formik.errors.contact_number && formik.touched.contact_number
                ? formik.errors.contact_number
                : null}
            </div>
          </div>
        )}
        {renderedInputs?.buyerOrSeller && formik.values.seller && (
          <>
            <Dropdown locale={locale}>
              <Select
                options={areas}
                name="area"
                placeholder={t('location')}
                instanceId="area-select"
                onChange={(selected: ListItemType) =>
                  handleListItemSelection('area', selected)
                }
                className="select"
                classNamePrefix="select"
                value={formik.values.area?.value ? formik.values.area : null}
              />
              <div className="form-errors">
                {formik.errors.area && formik.touched.area
                  ? formik.errors.area
                  : null}
              </div>
            </Dropdown>
            <Dropdown
              className="compound-dropdown"
              locale={locale}
              isDisabled={compounds?.length == 0}
            >
              <Select
                options={compounds}
                name="compound"
                placeholder={
                  formik.values.area?.value && compounds?.length == 0
                    ? t('no_available_compounds')
                    : t('compound')
                }
                noOptionsMessage={() => {
                  return t('no_options');
                }}
                instanceId="compound-select"
                onChange={(selected: ListItemType) =>
                  handleListItemSelection('compound', selected)
                }
                className="select"
                classNamePrefix="select"
                value={
                  formik.values.compound?.value ? formik.values.compound : null
                }
              />
              <div className="form-errors">
                {formik.errors.compound && formik.touched.compound
                  ? formik.errors.compound
                  : null}
              </div>
            </Dropdown>

            <Dropdown locale={locale}>
              <Select
                options={propertyTypes}
                name="property_type"
                placeholder={t('property_type')}
                instanceId="property-type-select"
                onChange={(selected: ListItemType) =>
                  handleListItemSelection('property_type', selected)
                }
                className="select"
                classNamePrefix="select"
                value={
                  formik.values.property_type?.value
                    ? formik.values.property_type
                    : null
                }
              />
              <div className="form-errors">
                {formik.errors.property_type && formik.touched.property_type
                  ? formik.errors.property_type
                  : null}
              </div>
            </Dropdown>
          </>
        )}

        {renderedInputs?.message && (
          <div className="form-input message-box">
            {withLabels && <label>{t('message')}</label>}
            <textarea
              id="message"
              placeholder={t('your_message')}
              name="contact_notes"
              className="input-message"
              value={formik.values.contact_notes}
              onChange={formik.handleChange}
            />
          </div>
        )}

        {formik.isSubmitting ? (
          <button data-test="submitting-btn">
            <i className="spinner" />
            {t('submitting')}
          </button>
        ) : (
          <button type="submit">{t('submit')}</button>
        )}
      </form>

      {layout?.footer && layout.footer}
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  max-width: 450px;
  background: ${props => props.theme.light.colors.whitish};
  border-radius: ${props => props.theme.borderRadius};
  padding: 32px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin: auto;
  margin-bottom: 16px;

  .form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .form-title {
      font-family: materialBold;
      text-transform: capitalize;
      color: #555;
      font-size: ${props => props.theme.font.sizes.xl};
    }

    h4 {
      margin-top: 16px;
      margin-bottom: 4px;
    }
    p {
      margin-bottom: 16px;
      font-size: ${props => props.theme.font.sizes.s};
      color: ${props => props.theme.light.colors.darkGrey};
    }

    .assistance-text {
      color: #555;
      font-family: materialRegular;
      font-size: ${props => props.theme.font.sizes.xxs};
    }
  }

  form {
    width: 100%;
    max-width: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    font-family: materialRegular;

    .phone-input {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: ${props => props.theme.light.backgroundColor};
      border-radius: ${props => props.theme.borderRadius};

      input {
        width: 100%;
        background-color: ${props => props.theme.light.backgroundColor};
        padding: 16px;
        border: none !important;
        border: 1px solid transparent;
        border-radius: ${props => props.theme.borderRadius};
        border-start-start-radius: 0;
        border-end-start-radius: 0;
        border-inline-start: none;

        ::placeholder {
          font-family: materialRegular;
        }

        &:focus {
          outline: none !important;
        }
      }
    }

    .form-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      label {
        font-size: ${props => props.theme.font.sizes.s};
        font-family: materialMedium;
        color: ${props => props.theme.light.colors.black};
        span {
          color: #ff3a00;
        }
      }
      input {
        width: 100%;
        height: 45px;
        padding: 12px 16px;
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
        border-radius: ${props => props.theme.borderRadius};
        outline: none;
        font-family: materialRegular;
      }
      .form-errors {
        font-size: ${props => props.theme.font.sizes.xxs};
        color: #e70800;
        margin-top: 4px;
        margin-inline-start: 5px;
        font-family: materialRegular;
      }
    }

    .message-box {
      height: auto;
      width: 100%;

      textarea {
        width: 100%;
        padding: 12px 16px;
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
        border-radius: ${props => props.theme.borderRadius};
        font-size: 14px;
        outline: none;
        min-height: 18px;
        font-family: materialRegular;
        resize: none;
      }
    }

    button {
      width: 100%;
      height: 45px;
      font-size: 14px;

      border: none;
      background-color: ${props => props.theme.light.colors.primaryBlue};
      color: white;
      border-radius: ${props => props.theme.borderRadius};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-family: materialRegular;
      cursor: pointer;

      &:hover {
        background-color: ${props => props.theme.light.hoverColors.primaryBlue};
      }

      .spinner {
        border: 4px solid rgba(255, 255, 255, 0.2);
        border-top: 4px solid #ffffff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .buyer-seller-tabs {
      width: 100%;
      display: flex;
      align-items: center;

      div {
        width: 50%;
        height: 50px;
        text-align: center;
        padding: 12px 0px;
        font-family: materialBold;
        font-size: ${props => props.theme.font.sizes.m};
        color: ${props => props.theme.light.colors.secondaryBlue};
        border-bottom: 2px solid #b6b6b6;
        cursor: pointer;

        &.active-tab {
          border-bottom: 2px solid #1e4164;
        }
      }
    }
  }
  .headline {
    margin: 8px 0 5px 0;
    h3 {
      font-size: ${props => props.theme.font.sizes.l};
      text-align: center;
    }
  }
`;

export default ContactFormPresentation;
