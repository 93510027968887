interface SearchIconProps {
  fill?: string;
  width?: string;
  height?: string;
}
const SearchIcon = ({ fill, width, height }: SearchIconProps) => {
  return (
    <svg
      width={width ?? '26'}
      height={height ?? '26'}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7375 17.2847C21.911 17.4585 22.0713 17.6457 22.2448 17.8195C24.1808 19.7582 26.1168 21.6969 28.0528 23.6355C29.4147 25.0127 29.3613 26.4968 27.906 27.9407C26.4907 29.358 24.9953 29.3981 23.6468 28.0477C21.6573 26.0689 19.6813 24.0901 17.7052 22.0846C17.4382 21.8038 17.2379 21.7504 16.8641 21.9375C9.72091 25.4405 1.563 21.2824 0.174425 13.4475C-0.973823 7.12339 3.68593 0.852782 10.0681 0.0906825C17.5317 -0.805119 23.7002 5.05101 23.1661 12.565C23.0593 14.2229 22.5519 15.7873 21.7375 17.2847ZM11.6035 2.1898C6.36962 2.20317 2.20389 6.4014 2.21724 11.6425C2.23059 16.8301 6.42303 21.0016 11.6169 21.0016C16.824 21.0016 21.0565 16.7365 21.0165 11.5489C20.9898 6.36129 16.7706 2.17643 11.6035 2.1898Z"
        fill={fill ?? 'white'}
      />
    </svg>
  );
};
export default SearchIcon;
