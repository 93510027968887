interface RemarketingEventData {
  listingIds: number[];
  listingPrice?: number;
}

const googleAdsAccounts = [
  'AW-877812802',
  'AW-11334367330',
  'AW-11334382153',
  'AW-11334416028',
  'AW-11334475571'
];

export const trackEvent = {
  viewContent: ({ listingIds, listingPrice }: RemarketingEventData) => {
    fbq('track', 'ViewContent', {
      content_type: 'home_listing',
      content_ids: listingIds,
      currency: 'EGP',
      value: listingPrice
    });
    gtag('event', 'view_item_list', {
      send_to: googleAdsAccounts,
      value: listingPrice,
      items: listingIds.map(listing => ({
        id: listing,
        google_business_vertical: 'real_estate'
      }))
    });
  },

  initiateCheckout: ({ listingIds, listingPrice }: RemarketingEventData) => {
    fbq('track', 'InitiateCheckout', {
      content_type: 'home_listing',
      content_ids: listingIds,
      currency: 'EGP',
      value: listingPrice
    });
    gtag('event', 'add_to_cart', {
      send_to: googleAdsAccounts,
      value: listingPrice,
      items: listingIds.map(listing => ({
        id: listing,
        google_business_vertical: 'real_estate'
      }))
    });
  },

  purchase: ({ listingIds, listingPrice }: RemarketingEventData) => {
    fbq('track', 'Purchase', {
      content_type: 'home_listing',
      content_ids: listingIds,
      currency: 'EGP',
      value: listingPrice
    });
    gtag('event', 'purchase', {
      send_to: googleAdsAccounts,
      value: listingPrice,
      items: listingIds.map(listing => ({
        id: listing,
        google_business_vertical: 'real_estate'
      }))
    });
  },

  search: ({ listingIds }: RemarketingEventData) => {
    fbq('track', 'Search', {
      content_type: 'home_listing',
      content_ids: listingIds,
      country: 'Egypt'
    });
    gtag('event', 'view_search_results', {
      send_to: googleAdsAccounts,
      items: listingIds?.map(listing => ({
        id: listing,
        google_business_vertical: 'real_estate'
      }))
    });
  }
};
