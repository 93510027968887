import styled from 'styled-components';
import NawyNow from '../../../../../new-components/svg-icons/NawyNow';
import CheckBoxContainer from '../checkbox-view/CheckboxContainer';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { useTranslation } from 'next-i18next';

const FinancingFilter = () => {
  const { t } = useTranslation('search');

  return (
    <Container>
      <div className="nawy-now">
        <div className="filter-info">
          <NawyNow />

          <div className="text-info">
            <h2>Nawy Now</h2>
            <span>{t('nawy_now_subtitle')}</span>
          </div>
        </div>

        <CheckBoxContainer
          options={[
            {
              filterType: SearchFiltersTypes.NAWY_NOW,
              value: 'nawy_now',
              options: { displayedValue: '' }
            }
          ]}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.light.colors.lightGrey};

  .nawy-now {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;

    .filter-info {
      display: flex;
      align-items: center;
      gap: 8px;

      .text-info {
        h2 {
          color: #000;
          font-size: 16px;
          font-family: materialBold;
        }
        span {
          font-size: 12px;
          color: #475467;
        }
      }
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    .nawy-now {
      .filter-info {
        .text-info {
          h2 {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export default FinancingFilter;
