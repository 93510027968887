import styled from 'styled-components';

interface ChildProps {
  height: string;
}

const GradientLine = ({ height }: ChildProps): JSX.Element => {
  return <Line height={height} className="gradient-line"></Line>;
};

const Line = styled.div<ChildProps>`
  width: 100%;
  height: ${props => props.height};
  background: #e6e6e6;
`;

export default GradientLine;
