import { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

interface buttonProps {
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  hoverColor?: string;
  btnClass?: string;
  containerClass?: string;
  border?: string;
  icon: ReactNode;
  dataTest?: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

interface containerProps {
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  hoverColor?: string;
  border?: string;
}

const ButtonIcon = ({
  backgroundColor,
  backgroundHover,
  color,
  hoverColor,
  border,
  icon,
  btnClass,
  containerClass,
  onClick,
  dataTest
}: buttonProps) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      backgroundHover={backgroundHover}
      color={color}
      hoverColor={hoverColor}
      border={border}
      className={containerClass}
      data-test={dataTest}
    >
      <button className={btnClass} onClick={onClick} aria-label="button-icon">
        {icon}
      </button>
    </Container>
  );
};

const Container = styled.div<containerProps>`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: ${props => props.border};
    background-color: ${props => props.backgroundColor};
    cursor: pointer;
    transition: all 0.3s ease-out;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${props => (props.color == 'none' ? '' : props.color)};
      }
    }

    @media screen and (min-width: ${props =>
        props.theme.breakPoints.desktop.min}) {
      :hover {
        background-color: ${props => props.backgroundHover};
        svg {
          path {
            fill: ${props => props.hoverColor};
          }
        }
      }
    }
  }
`;
export default ButtonIcon;
