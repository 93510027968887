import {
  FilterInputTypes,
  SearchFiltersActionTypes
} from '../../../../../reducers/search-filters/actions';
import { useRouter } from 'next/router';
import { updateQuery } from '../../../../../helpers/common';
import { unCommifyNumber } from '../../../../../lib/helpers';
import SingleInputPresentation from './SingleInputPresentation';
import { useContext } from 'react';
import { logEvent } from '../../../../../new-lib/analytics';
import { filterEventMap } from '../../../../../helpers/search-filters';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { SearchContext, SearchContextInterface } from '../../../../search-new/context/SearchContext';

interface ChildProps {
  filterType: FilterInputTypes;
  headline: string;
  subtext?: string;
  curruncy?: string;
  placeholder: string;
  min: number;
}

const SingleInputContainer = ({
  filterType,
  headline,
  subtext,
  curruncy,
  placeholder,
  min
}: ChildProps): JSX.Element => {
  const { searchFiltersDispatch, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    let value = parseInt(unCommifyNumber(e.currentTarget.value));
    if (value >= min) {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: filterType,
          value: value
        }
      });
      logEvent.search(
        `filter_${filterEventMap(filterType)}`,
        'filtersMenu',
        'filter',
        searchFiltersState,
        { [filterType]: value }
      );

      if (!router.asPath.includes('/sahel-map')) {
        updateQuery(
          router,
          [
            {
              name: filterType,
              value: value
            },
            { name: 'page_number', value: 1 }
          ],
          undefined
        );
      }
    } else {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: filterType,
          value: undefined
        }
      });

      if (!router.asPath.includes('/sahel-map')) {
        updateQuery(
          router,
          !router.asPath.includes('/nawy-now') &&
            searchFiltersState.searchFilters[filterType] &&
            searchFiltersState.page_number &&
            searchFiltersState.page_number > 1
            ? [{ name: 'page_number', value: 1 }]
            : [],
          [filterType]
        );
        updateQuery(router, undefined, [filterType]);
      }
    }
  };

  return (
    <SingleInputPresentation
      handleInput={handleInput}
      headline={headline}
      placeHolder={placeholder}
      initState={searchFiltersState.searchFilters[filterType]}
      subtext={subtext}
      curruncy={curruncy}
      min={min}
    />
  );
};

export default SingleInputContainer;
