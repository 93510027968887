import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useAgentVerification from '../../hooks/AgentVerification';
import AgentIcon from '../svg-icons/AgentIcon';
import styled from 'styled-components';
import ProfileIcon from '../svg-icons/ProfileIcon';
import { Dispatch, SetStateAction } from 'react';
import CheckMark from '../svg-icons/CheckMark';
import InvalidIcon from '../svg-icons/InvalidIcon';

interface VerifyAgentProps {
  setShowVerification: Dispatch<SetStateAction<boolean>>;
}

const VerifyAgentComponent = ({ setShowVerification }: VerifyAgentProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const {
    phoneNumber,
    agentInfo,
    checkAgent,
    handleInputChange,
    handleVerifyAgent,
    handlePaste
  } = useAgentVerification();

  const handleBackButton = () => {
    if (isMobile) {
      router.back();
    } else {
      setShowVerification(false);
    }
  };

  const getErrorComponent = () => {
    return agentInfo.errorMessage ? (
      <p className="invalid-message">{t(agentInfo.errorMessage)}</p>
    ) : (
      <>
        <span className={`status-header invalid`}>
          {t('agent_invalid', { ns: 'common' })}
          <span className="invalid-icon">
            <InvalidIcon />
          </span>
        </span>
        <span className="status-subtitle">
          {t('agent_invalid_subtitle', { ns: 'common' })}{' '}
          <span dir="ltr">{'+201065888849'}</span>
        </span>
      </>
    );
  };

  const getAgentStatus = () => {
    return (
      <div className={`status ${agentInfo.success ? 'valid' : 'invalid'}`}>
        {agentInfo.success ? (
          <>
            <span className={`status-header valid`}>
              {t('agent_verified', { ns: 'common' })}
              <span className="check-icon">
                <CheckMark stroke="#fff" />
              </span>
            </span>
            <span className="status-subtitle">
              {t('agent_verified_subtitle', { ns: 'common' })}
            </span>
            <div className="agent-info">
              <div className="agent-icon">
                <AgentIcon />
              </div>
              <div className="agent-name">
                <span className="agent-title">
                  {t('agent_name', { ns: 'common' })}
                </span>
                <span className="name">{agentInfo.name}</span>
              </div>
            </div>
          </>
        ) : (
          getErrorComponent()
        )}
      </div>
    );
  };

  return (
    <Container isMobile={isMobile}>
      <ProfileIcon />
      <div className="header">
        <span className="title">{t('verification_title')}</span>
        <span className="subtitle">
          {t('verification_subtitle_first')}{' '}
          <span className="highlighted">
            {t('verification_subtitle_highlighted')}
          </span>{' '}
          {t('verification_subtitle_last')}
        </span>
      </div>

      <div className="verify-agent-status">
        <div className="input-container">
          <span className="input-header">{t('agent_number')}</span>
          <input
            maxLength={11}
            onPaste={handlePaste}
            placeholder="0100123456"
            type="text"
            value={phoneNumber ?? ''}
            onChange={handleInputChange}
            className={agentInfo.isInvalidNumber ? 'invalid' : ''}
          />
        </div>
        <div className={`status-container ${isMobile ? 'sm' : ''}`}>
          {agentInfo.isInvalidNumber && (
            <p className="invalid-message">{t('enter_valid_number')}</p>
          )}
          {checkAgent && !agentInfo.isInvalidNumber && getAgentStatus()}
        </div>
      </div>

      <div className="btns-container">
        <button onClick={handleBackButton}>
          {isMobile
            ? t('back', { ns: 'common' })
            : t('cancel', { ns: 'common' })}
        </button>
        <button
          className="verify-btn"
          onClick={handleVerifyAgent}
          disabled={phoneNumber?.length !== 11}
        >
          {t('verify')}
        </button>
      </div>
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-bottom: 25px;

    .title {
      padding-top: 24px;
      padding-bottom: 4px;
      color: #101828;
      text-align: center;
      font-family: materialSemiBold;
      font-size: 18px;
    }
    .subtitle {
      text-align: center;
      color: #475467;
      font-family: materialMedium;
      font-size: 14px;

      .highlighted {
        color: #ff5e00;
        font-size: 14px;
        font-family: materialBold;
      }
    }
  }

  .verify-agent-status {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input-container {
      padding: 20px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .input-header {
        color: #5b6573;
        font-family: materialRegular;
        font-size: 14px;
      }

      input {
        width: 100%;
        padding: 10px 14px 10px 8px;
        border-radius: 8px;
        border: 1px solid #b0bdcf;
        background: #f7f9fd;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &.invalid {
          border-color: #da3e33;
        }
      }
    }

    .status-container {
      &.sm {
        min-height: 127px;
      }
      .invalid-message {
        color: #da3e33;
        font-family: materialRegular;
        font-size: 12px;
      }
    }

    .status {
      display: flex;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 4px;
      &.valid {
        background: #e7f8f0;
      }
      &.invalid {
        background: #feeceb;
      }

      .status-header {
        font-family: materialMedium;
        font-size: 16px;
        display: flex;
        gap: 4px;
        align-items: center;

        &.valid {
          color: #12b76a;
          .check-icon {
            border-radius: 50%;
            background: #12b76a;
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            padding: 3px;
          }
        }
        &.invalid {
          color: #da3e33;
          .invalid-icon {
            border-radius: 50%;
            width: 16px;
            height: 16px;
            align-self: flex-start;
            padding: 1px;
          }
        }
      }

      .status-subtitle {
        color: #002741;
        font-family: materialMedium;
        font-size: 12px;
      }

      .agent-info {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .agent-icon {
          display: flex;
          width: 32px;
          height: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #fff;
        }
        .agent-name {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .agent-title {
            color: #697586;
            font-family: materialRegular;
            font-size: 12px;
          }
          .name {
            color: #2c3138;
            font-family: materialregular;
            font-size: 14px;
          }
        }
      }
    }
  }

  .btns-container {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    padding-top: 24px;
    ${props => !props.isMobile && 'margin-top: 16px;'}
    gap: 12px;
    border-top: 1px solid #eaecf0;

    button {
      width: 100%;
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #4b535f;
      font-family: materialRegular;
      font-size: 16px;

      &.verify-btn {
        background: #015c9a;
        color: #fff;
        font-family: materialRegular;
        font-size: 16px;

        &:disabled {
          background: #8e8e8e;
        }
      }
    }
  }
`;

export default VerifyAgentComponent;
