import styled from 'styled-components';
import { logEvent } from '../../new-lib/analytics';
import AppleDownloadIcon from '../svg-icons/AppleDownloadIcon';
import GoogleDownloadIcon from '../svg-icons/GoogleDownloadIcon';

interface ChildProps {
  iconWidth: string;
  iconColor: string;
  iconHeight?: string;
  eventLocation: string;
}

export default function DownloadApp({
  iconWidth,
  iconColor,
  iconHeight,
  eventLocation
}: ChildProps) {
  return (
    <Container
      iconColor={iconColor}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
    >
      <div className="download-btns" itemScope itemType="http://schema.org/DownloadAction">
        <a
          itemProp='url'
          href="https://apps.apple.com/eg/app/nawy-real-estate/id1571474485"
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            logEvent.click('download_app', eventLocation, 'other', {
              download_platform: 'ios'
            })
          }
          aria-label="download-link"
        >
          <AppleDownloadIcon />
        </a>
        <a
          itemProp='url'
          href="https://play.google.com/store/apps/details?id=com.cooingestate.cooing_app&hl=en&gl=US"
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            logEvent.click('download_app', eventLocation, 'other', {
              download_platform: 'android'
            })
          }
          aria-label="download-link"
        >
          <GoogleDownloadIcon />
        </a>
      </div>
    </Container>
  );
}

const Container = styled.div<{
  iconWidth: string;
  iconColor: string;
  iconHeight?: string;
}>`
  display: flex;
  flex-direction: column;

  .download-btns {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;

    a {
      width: ${props => props.iconWidth};
      height: 100%;
      height: ${props => props.iconHeight};

      svg {
        width: ${props => props.iconWidth};
        height: 100%;
        path {
          fill: ${props => props.iconColor};
        }
      }
    }
  }
`;
