export const WebviewElementsMap = new Map<string, string[]>();

WebviewElementsMap.set('partners', [
  'cta',
  'cta-container',
  'lower-details',
  'breadcrumbs',
  'launches-description',
  'trending-projects'
]);
