interface IconProps {
  width?: number;
  height?: number;
}

const NawyNow = ({ width, height }: IconProps) => {
  return (
    <svg
      width={width ?? '37'}
      height={height ?? '37'}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0515 11.8303C17.5586 9.48072 11.9373 5.33739 7.75611 0.00665283H0.0126321C0.0126321 0.183502 0 0.347719 0 0.524568C0 20.6727 16.3333 36.9934 36.4689 36.9934C36.6457 36.9934 36.8099 36.9808 36.9868 36.9808V29.8184C30.9992 25.3592 26.4137 19.0937 24.0515 11.8303Z"
        fill="#FD4F02"
      />
      <path
        d="M36.9991 13.9904V0.00665283H7.75586C14.4382 8.52067 24.8092 14.003 36.4686 14.003C36.6454 14.003 36.8223 13.9904 36.9991 13.9904Z"
        fill="#F97C4B"
      />
      <path
        d="M37.0002 29.8184V0.00665283H22.2712C22.2712 0.183502 22.2585 0.347719 22.2585 0.524568C22.2585 12.525 28.0567 23.1739 37.0002 29.8184Z"
        fill="#D60707"
      />
    </svg>
  );
};

export default NawyNow;
