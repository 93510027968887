import React from 'react';
import styled from 'styled-components';
import NumberInput from '../../../../../new-components/NumberInput';

interface ChildProps {
  handleInput: (e: React.FormEvent<HTMLInputElement>) => void;
  initState: number | undefined;
  placeHolder: string;
  headline?: string;
  subtext?: string;
  curruncy?: string;
  min: number;
}

const SingleInputPresentation = ({
  handleInput,
  initState,
  headline,
  subtext,
  placeHolder,
  curruncy,
  min
}: ChildProps): JSX.Element => {
  return (
    <>
      <Container>
        <div className="filter-headline">
          <h3 className="text-3">{headline}</h3>
          <span className="text-1">{subtext}</span>
        </div>
        <div className="filter-container">
          <NumberInput
            placeholder={placeHolder}
            width={227}
            onBlur={handleInput}
            initValue={initState}
            min={min}
            onSelect={() => {}}
          />
          <span className="text-1">{curruncy}</span>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  .filter-headline {
    display: flex;
    flex-direction: row;
    gap: 6px;
    margin-bottom: 8px;
    h3 {
      font-size: ${props => props.theme.font.sizes.s};
      font-family: materialMedium;
    }
    span {
      font-size: ${props => props.theme.font.sizes.xxs};
    }
  }
  .filter-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    margin-bottom: 8px;

    span {
      font-size: ${props => props.theme.font.sizes.xxs};
    }
  }
`;

export default SingleInputPresentation;
