import styled from 'styled-components';
// import Icon from '../Icon';
import { ReactNode, MouseEvent } from 'react';

interface buttonProps {
  text: string;
  subText?: string;
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  containerClass?: string;
  btnClass?: string;
  hoverColor?: string;
  fontSize: string;
  padding: string;
  border?: string;
  icon: ReactNode;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  dataTest?: string;
}

interface containerProps {
  backgroundColor: string;
  backgroundHover?: string;
  color: string;
  hoverColor?: string;
  fontSize: string;
  padding: string;
  border?: string;
}

const ButtonTextIcon = ({
  text,
  subText,
  backgroundColor,
  backgroundHover,
  color,
  hoverColor,
  fontSize,
  padding,
  border,
  icon,
  btnClass,
  containerClass,
  onClick,
  dataTest
}: buttonProps) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      backgroundHover={backgroundHover}
      color={color}
      hoverColor={hoverColor}
      fontSize={fontSize}
      padding={padding}
      border={border}
      className={containerClass}
      data-test={dataTest}
    >
      <button onClick={(e: any) => onClick(e)} className={btnClass}>
        {icon}
        <div className="btn-text">
          {subText && <span className="sub-text">{subText}</span>}
          <span>{text}</span>
        </div>
      </button>
    </Container>
  );
};

const Container = styled.div<containerProps>`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: materialRegular;
    border-radius: ${props => props.theme.borderRadius};
    height: 35px;
    border: ${props => props.border};
    background-color: ${props => props.backgroundColor};
    font-size: ${props => props.fontSize};
    padding: ${props => props.padding};
    color: ${props => props.color};
    transition: all 0.2s ease-out;
    cursor: pointer;

    .btn-text {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      .sub-text {
        font-size: 8px;
      }
    }
    svg {
      path {
        min-width: 27px;
        height: auto;
        transition: all 0.2s ease-out;
        fill: ${props => props.color};
      }
    }
    span {
      margin: 0px 5px;
    }

    @media screen and (min-width: ${props =>
        props.theme.breakPoints.desktop.min}) {
      :hover {
        background-color: ${props => props.backgroundHover};
        color: ${props => props.hoverColor};

        svg {
          path {
            fill: ${props =>
              props.hoverColor == 'none' ? '' : props.hoverColor};
          }
        }
      }
    }
  }
`;
export default ButtonTextIcon;
