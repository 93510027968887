import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react';
import { SearchSelection, SelectedItem } from './SearchSelections';
import { logEvent } from '../../../../new-lib/analytics';
import { getPageLocation } from '../../../../helpers/common';
import { useRouter } from 'next/router';
import debounce from '../../utils';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search-new/context/SearchContext';
import { useSelector } from 'react-redux';
import SearchIcon from '../../../../new-components/svg-icons/SearchIcon';

interface SearchInputProps {
  dropdownOpen: boolean;
  setDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  selectedSearches: SearchSelection | null;
  placeholder?: string;
  clearSearch: () => void;
  handleRemove: (selection: SearchSelection) => void;
}

const SearchInput = ({
  dropdownOpen,
  setDropdownOpen,
  setSearchTerm,
  selectedSearches,
  placeholder,
  clearSearch,
  handleRemove
}: SearchInputProps) => {
  const { t } = useTranslation('common');
  const { pathname } = useRouter();
  const searchInput = useRef<HTMLInputElement>(null);
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const { setShowUniversalSearch } = useContext(
    SearchContext
  ) as SearchContextInterface;

  useEffect(() => {
    if (dropdownOpen && searchInput?.current) {
      searchInput?.current?.focus();
    }
  }, [dropdownOpen]);

  const handleEnter = (e: any) => {
    if (e.key == 'Enter') {
      setDropdownOpen(false);
      setShowUniversalSearch(false);
    }
  };
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouncedResults(e.currentTarget.value);
  };
  const clearBtnRef = useRef<HTMLButtonElement>(null);
  const debouncedResults = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 500),
    []
  );

  return dropdownOpen ? (
    <OpenedInputContainer className="opened-input">
      <input
        type="text"
        name="search-input"
        id="search-input"
        ref={searchInput}
        autoFocus
        onChange={e => handleInputChange(e)}
        onKeyDown={e => handleEnter(e)}
        autoComplete="off"
        data-cy="search-input"
      />
    </OpenedInputContainer>
  ) : (
    <ClosedInputContainer
      onClick={() => {
        setDropdownOpen(true);
        logEvent.click('search_click', getPageLocation(pathname), 'search');
      }}
      className="closed-input"
      data-test="closed-search-input"
    >
      {selectedSearches && pathname === '/' ? (
        <>
          <SelectedItem
            selection={selectedSearches}
            handleRemove={handleRemove}
          />
        </>
      ) : (
        <>
          <SearchIcon fill={'#788496'} />
          <span className="placeholder">
            {placeholder || t('search_by_locations')}
          </span>
        </>
      )}
    </ClosedInputContainer>
  );
};

const ClosedInputContainer = styled.div`
  width: 100%;
  border: 1px solid #c6d0de;
  border-radius: 10px;
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  svg {
    width: 20px;
    height: 20px;
  }

  span {
    margin-inline-start: 8px;
    font-size: ${props => props.theme.font.sizes.s};
    color: ${props => props.theme.light.colors.grey};
  }

  .selected-search {
    background-color: transparent;
    border: 1px solid #015c9a;
    margin-inline-end: 4px;
    max-width: 120px;
    span {
      color: #015c9a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-family: materialMedium;
      margin-inline-start: 0;
    }

    &.more-searches {
      border-radius: 8px;
      padding: 4px 8px;
    }
  }
  button.clear {
    background: none;
    border: none;
    color: #1e6dac;
    font-size: 12px;
    font-family: materialRegular;
    cursor: pointer;
    margin-inline-start: auto;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    height: 45px;
    background-color: #f7f9fd;
    border: 1px solid #d3dbe6;
    border-radius: 12px;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .selected-search {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
const OpenedInputContainer = styled.div`
  width: 100%;
  border: 1px solid #c6d0de;
  border-radius: 10px;
  height: 100%;

  input {
    width: 100%;
    padding: 12px 8px;
    outline: none;
    border: none;
    background: none;
    color: ${props => props.theme.light.colors.black};
  }
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    height: 45px;
    background-color: #f7f9fd;
    border: 1px solid #d3dbe6;
    border-radius: 12px;
  }
`;

export default SearchInput;
