import { commifyNumber } from '../../common';
import { SingleValue } from './SingleValue';

interface SingleValueType {
  value: string | number;
  options: any;
}

type InputValues = string[] | number[] | object[];

export class OptionListValues {
  constructor(
    public inputValues?: InputValues,
    public outputOptions?: SingleValueType[],
    public mapDisplayedValues?: any
  ) {
    this.inputValues = inputValues;
    this.outputOptions = [];
    this.createOptions();
  }
  // list of single values like finishing  to [{value:,options:{displayedValues:}}]

  createOptions = (): void => {
    if (!this.inputValues) return;
    const optionResults = this.inputValues.map(curr => {
      let displayed;
      const inputValue = Object.create(SingleValue);
      if (typeof curr == 'string' || typeof curr == 'number') {
        if (this.mapDisplayedValues && this.mapDisplayedValues[curr]) {
          displayed = this.mapDisplayedValues[curr];
        } else {
          displayed = this.createDisplayedValue(curr);
        }
        inputValue.constructor(curr, {
          displayedValue: displayed
        });

        return inputValue;
      }
    });

    this.outputOptions = optionResults;
  };

  createDisplayedValue = (curr: string | number): string => {
    if (typeof curr == 'number') {
      curr = commifyNumber(curr);
    }

    return curr;
  };
}
