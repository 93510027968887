import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

interface ContainerProps {
  option: { value: string; options: { displayedValue: string } };
  selectedValues: any;
  handleSelect: any;
  singleSelect?: boolean;
  isNawyNowSelected?: boolean;
}

const ChipPresentation = ({
  selectedValues,
  handleSelect,
  option,
  singleSelect,
  isNawyNowSelected
}: ContainerProps) => {
  const { t } = useTranslation('common');
  return (
    <ChipBtn
      key={option.value}
      onClick={() => handleSelect(option)}
      className={isNawyNowSelected ? 'disabled' : ''}
      isSelected={
        singleSelect
          ? selectedValues == option.value
          : selectedValues.includes(option.value)
      }
    >
      {t(option.options.displayedValue)}
    </ChipBtn>
  );
};

const ChipBtn = styled.button<{ isSelected: boolean }>`
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 360px;
  background-color: ${props => (props.isSelected ? '#015c9a' : '#fff')};
  color: ${props => (props.isSelected ? '#fff' : '#5b6573')};
  font-family: materialRegular;
  &.disabled {
    &:first-child {
      background-color: #015c9a;
      color: #fff;
    }
    background-color: #e0e0e0;
    color: #5b6573;
    pointer-events: none;
  }
`;

export default ChipPresentation;
