import { createSlice } from '@reduxjs/toolkit';
import { logEvent } from '../../new-lib/analytics';

interface CompareState {
  properties_ids: number[];
  compounds_ids: number[];
  compare_type: 'properties' | 'compounds';
  compare_count: number;
  compare_mobile_max_count: number;
  show_popup: boolean;
  show_compare_tooltip: boolean;
}

const initialState: CompareState = {
  properties_ids: [],
  compounds_ids: [],
  compare_type: 'compounds',
  compare_count: 0,
  compare_mobile_max_count: 4,
  show_popup: false,
  show_compare_tooltip: true
};
export const compareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    initializeCompareState: (state, action) => {
      return {
        ...state,
        compounds_ids: action.payload.compounds_ids,
        properties_ids: action.payload.properties_ids,
        compare_type: action.payload.compare_type,
        compare_count: action.payload.compare_count,
        show_popup: action.payload.show_popup,
        show_compare_tooltip: action.payload.show_compare_tooltip
      };
    },
    updateCompareItems: (state, action) => {
      const compareType: 'properties' | 'compounds' =
        action.payload.compare_type;
      const items_ids = state[`${compareType}_ids`];
      if (items_ids.includes(action.payload.item_id)) {
        const updatedItems = items_ids.filter(
          (id: number) => id !== action.payload.item_id
        );
        if (action.payload.fireEvent) {
          logEvent.click('cmpr_remove', 'compare', 'compare', {
            [compareType == 'properties' ? 'property_id' : 'compound_id']:
              action.payload.item_id
          });
        }
        return {
          ...state,
          [`${action.payload.compare_type}_ids`]: updatedItems,
          compare_count: state.compare_count - 1
        };
      } else if (
        action.payload.isMobile &&
        items_ids.length >= state.compare_mobile_max_count
      ) {
        return { ...state, show_popup: true };
      } else {
        const updatedItems = [...items_ids, action.payload.item_id];
        if (action.payload.fireEvent) {
          logEvent.click('cmpr_add', 'compare', 'compare', {
            [compareType == 'properties' ? 'property_id' : 'compound_id']:
              action.payload.item_id
          });
        }
        return {
          ...state,
          ...(action.payload.compare_type === 'properties' &&
            state.compounds_ids.length === 0 && { compare_type: 'properties' }),
          [`${action.payload.compare_type}_ids`]: updatedItems,
          compare_count: state.compare_count + 1
        };
      }
    },
    setCompareType: (state, action) => {
      state.compare_type = action.payload;
    },
    resetCompareState: state => {
      return {
        ...initialState,
        show_compare_tooltip: state.show_compare_tooltip
      };
    },
    hidePopup: state => {
      state.show_popup = false;
    },
    showPopup: state => {
      state.show_popup = true;
    },
    hideCompareTooltip: state => {
      state.show_compare_tooltip = false;
    }
  }
});
export const {
  initializeCompareState,
  updateCompareItems,
  setCompareType,
  resetCompareState,
  showPopup,
  hidePopup,
  hideCompareTooltip
} = compareSlice.actions;
export default compareSlice.reducer;
