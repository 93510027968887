import React from 'react';
import {
  FilterInputTypes,
  SearchFiltersActionTypes
} from '../../../../../reducers/search-filters/actions';
import { useRouter } from 'next/router';
import { unCommifyNumber } from '../../../../../lib/helpers';
import { updateQuery } from '../../../../../helpers/common';
import MinMaxFilterPresentation from './MinMaxInputPresentation';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import { logEvent } from '../../../../../new-lib/analytics';
import { filterEventMap } from '../../../../../helpers/search-filters';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { SearchContext, SearchContextInterface } from '../../../../search-new/context/SearchContext';

interface ChildProps {
  minFilterType: FilterInputTypes;
  maxFilterType: FilterInputTypes;
  min: number;
  minList?: InputOption[];
  maxList?: InputOption[];
}

const MinMaxInputContainer = ({
  minFilterType,
  maxFilterType,
  min,
  minList,
  maxList
}: ChildProps): JSX.Element => {
  const { searchFiltersDispatch, searchFiltersState } = React.useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();
  const handleMin = (e: React.FormEvent<HTMLInputElement>) => {
    let value;

    if (typeof e.currentTarget.value == 'string') {
      value = parseInt(unCommifyNumber(e.currentTarget.value));
    } else {
      value = e.currentTarget.value;
    }
    if (value >= min) {
      if (value !== searchFiltersState.searchFilters[minFilterType]) {
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: minFilterType,
            value: value
          }
        });
        logEvent.search(
          `filter_${filterEventMap(minFilterType)}`,
          'filtersMenu',
          'filter',
          searchFiltersState,
          { [minFilterType]: value }
        );

        if (!router.asPath.includes('/sahel-map')) {
          updateQuery(
            router,
            [
              {
                name: minFilterType,
                value: value
              },
              ...(!router.asPath.includes('/nawy-now')
                ? [{ name: 'page_number', value: 1 }]
                : [])
            ],
            undefined
          );
        }
      }
    } else {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: minFilterType,
          value: undefined
        }
      });
      if (!router.asPath.includes('/sahel-map')) {
        updateQuery(
          router,
          [
            ...(!router.asPath.includes('/nawy-now')
              ? [{ name: 'page_number', value: 1 }]
              : [])
          ],
          [minFilterType]
        );
        updateQuery(router, undefined, [minFilterType]);
      }
    }
  };

  const handleMax = (e: React.FormEvent<HTMLInputElement>) => {
    let value;

    if (typeof e.currentTarget.value == 'string') {
      value = parseInt(unCommifyNumber(e.currentTarget.value));
    } else {
      value = e.currentTarget.value;
    }
    if (value > min) {
      if (value !== searchFiltersState.searchFilters[maxFilterType]) {
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: maxFilterType,
            value: value
          }
        });
        logEvent.search(
          `filter_${filterEventMap(maxFilterType)}`,
          'filtersMenu',
          'filter',
          searchFiltersState,
          { [maxFilterType]: value }
        );

        if (!router.asPath.includes('/sahel-map')) {
          updateQuery(
            router,
            [
              {
                name: maxFilterType,
                value: value
              },
              ...(!router.asPath.includes('/nawy-now')
                ? [{ name: 'page_number', value: 1 }]
                : [])
            ],
            undefined
          );
        }
      }
    } else {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: maxFilterType,
          value: undefined
        }
      });
      if (!router.asPath.includes('/sahel-map')) {
        updateQuery(
          router,
          [
            ...(!router.asPath.includes('/nawy-now')
              ? [{ name: 'page_number', value: 1 }]
              : [])
          ],
          [maxFilterType]
        );
        updateQuery(router, undefined, [maxFilterType]);
      }
    }
  };

  return (
    <MinMaxFilterPresentation
      handleMin={handleMin}
      handleMax={handleMax}
      minList={minList}
      maxList={maxList}
      minState={searchFiltersState.searchFilters[minFilterType]}
      maxState={searchFiltersState.searchFilters[maxFilterType]}
      min={min}
    />
  );
};

export default MinMaxInputContainer;
